import styled from "styled-components";

const ExampleSlide = styled.div`
  & .swiper_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1500px;
    margin: 0 auto;
    & .swiper {
      width: 100%;
      padding: 50px 120px;
      & .swiper-slide {
        transition: 0.5s;
        opacity: 0;
        &.swiper-slide-active,
        &.swiper-slide-next {
          opacity: 1;
        }
        &.swiper-slide-next + .swiper-slide {
          opacity: 1;
          & + .swiper-slide {
            opacity: 1;
          }
        }
      }
      & .example_slide {
        position: relative;
        width: 300px;
        border-radius: 5px;
        box-shadow: 10px 10px 40px 0px rgba(90, 90, 90, 0.25);
        overflow: hidden;
        & .example_inner {
          padding: 30px 28px;
          & .example_tit {
            display: flex;
            align-items: center;
            gap: 10px;
            padding-bottom: 16px;
            margin-bottom: 14px;
            border-bottom: 2px solid rgb(218, 37, 11, 0.2);
            & img {
              width: 24px;
              height: 24px;
            }
            & span {
              font-size: 20px;
              line-height: 24px;
              font-weight: 500;
              color: #da250b;
            }
          }
          & .example_text {
            & .tit {
              font-size: 32px;
              line-height: 38px;
              font-weight: 800;
              text-align: left;
              color: #da250b;
            }
            & .dec {
              font-size: 14px;
              line-height: 20px;
              margin-top: 16px;
              font-weight: 500;
              text-align: left;
              letter-spacing: -0.4px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
            }
            & .date {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 14px;
              font-weight: bold;
              color: #888;
              margin-top: 30px;
              & span {
                font-weight: 500;
              }
            }
          }
          & .hover_img {
            position: absolute;
            top: 27px;
            left: 34px;
            width: 0;
            height: 330px;
            object-fit: cover;
            transition: 0.5s;
            opacity: 0;
          }
          &:hover {
            & .hover_img {
              width: 232px;
              opacity: 1;
            }
          }
        }
        & .btn_more {
          width: 100%;
          height: 50px;
          background-color: #da250b;
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
  & .slide_btn {
    position: absolute;
    bottom: 250px;
    width: 70px;
    height: 70px;
    z-index: 1;
    cursor: pointer;
    &.btn_prev {
      left: 0;
    }
    &.btn_next {
      right: 0;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
  & .swiper_wrap {
      width: calc(100vw*(1500/1520));
      & .swiper {
        padding: calc(100vw*(50/1520)) calc(100vw*(120/1520));
        & .example_slide {
          width: calc(100vw*(300/1520));
          border-radius: calc(100vw*(5/1520));
          box-shadow: calc(100vw*(10/1520)) calc(100vw*(10/1520)) calc(100vw*(40/1520)) 0 rgba(90, 90, 90, 0.25);
          & .example_inner {
            padding: calc(100vw*(30/1520)) calc(100vw*(28/1520));
            & .example_tit {
              gap: calc(100vw*(10/1520));
              padding-bottom: calc(100vw*(16/1520));
              margin-bottom: calc(100vw*(14/1520));
              & img {
                width: calc(100vw*(24/1520));
                height: calc(100vw*(24/1520));
              }
              & span {
                font-size: calc(100vw*(20/1520));
                line-height: calc(100vw*(24/1520));
              }
            }
            & .example_text {
              & .tit {
                font-size: calc(100vw*(32/1520));
                line-height: calc(100vw*(38/1520));
              }
              & .dec {
                font-size: calc(100vw*(14/1520));
                line-height: calc(100vw*(20/1520));
                margin-top: calc(100vw*(16/1520));
              }
              & .date {
                gap: calc(100vw*(10/1520));
                font-size: calc(100vw*(14/1520));
                margin-top: calc(100vw*(30/1520));
              }
            }
            & .hover_img {
              top: calc(100vw*(27/1520));
              left: calc(100vw*(34/1520));
              height: calc(100vw*(330/1520));
            }
            &:hover {
              & .hover_img {
                width: calc(100vw*(232/1520));
              }
            }
          }
          & .btn_more {
            height: calc(100vw*(50/1520));
            font-size: calc(100vw*(18/1520));
          }
        }
      }
    }
    & .slide_btn {
      bottom: calc(100vw*(250/1520));
      width: calc(100vw*(70/1520));
      height: calc(100vw*(70/1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
  & .swiper_wrap {
      width: 100%;
      & .swiper {
        padding: calc(100vw*(30/375)) calc(100vw*(40/375));
        opacity: 1;
        & .swiper-slide {
          width: calc(100vw*(256/375));
          opacity: 1;
        }
        & .example_slide {
          width: 100%;
          border-radius: calc(100vw*(5/375));
          box-shadow: calc(100vw*(8/375)) calc(100vw*(8/375)) calc(100vw*(20/375)) 0 rgba(90, 90, 90, 0.25);
          & .example_inner {
            padding: calc(100vw*(20/375)) calc(100vw*(23/375));
            & .example_tit {
              gap: calc(100vw*(5/375));
              padding-bottom: calc(100vw*(14/375));
              margin-bottom: calc(100vw*(14/375));
              & img {
                width: calc(100vw*(24/375));
                height: calc(100vw*(24/375));
              }
              & span {
                font-size: calc(100vw*(16/375));
                line-height: calc(100vw*(19/375));
              }
            }
            & .example_text {
              & .tit {
                font-size: calc(100vw*(22/375));
                line-height: calc(100vw*(26/375));
              }
              & .dec {
                font-size: calc(100vw*(12/375));
                line-height: calc(100vw*(17/375));
                margin-top: calc(100vw*(16/375));
              }
              & .date {
                gap: calc(100vw*(10/375));
                font-size: calc(100vw*(14/375));
                margin-top: calc(100vw*(15/375));
              }
            }
            & .hover_img {display: none;}
            &:hover {
              & .hover_img {
                width: calc(100vw*(232/375));
              }
            }
          }
          & .btn_more {
            height: calc(100vw*(50/375));
            font-size: calc(100vw*(16/375));
            font-weight: 600;
          }
        }
      }
    }
    & .slide_btn {display: none;}
  }
`;

export { ExampleSlide };

export default { ExampleSlide };
