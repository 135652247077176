import Styles from "./styles";

//img
import IconClose from "resources/image/icon/icon_close.svg";

function TermsModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="wrap">
        <button type="button" className="closeButton" onClick={modalClose}>
          닫기
          <img src={IconClose} alt="close" />
        </button>
        <h2 className="title">
          1:1 비밀상담 접수가 완료되었습니다.
        </h2>

        <div className="inner">
          <p className="text">
            상담접수 후 10분 내로 담당자가 <br className="mbBr" />연락드리도록 하겠습니다.<br />감사합니다.
          </p>
        </div>
      </div>

      <div className="overlay" onClick={modalClose}></div>
    </Styles.Container>
  );
}

export { TermsModal };

export default TermsModal;
