import styled, { css } from 'styled-components';

const Header = styled.div`
  position: fixed; top: 0; left: 0;
  width: 100%; height: calc(100vw*(70/375));
  color: #fff;
  overflow: hidden;
  z-index: 21;
  border-bottom: 1px solid #F5F5F5;
  &.on {
    background-color: #fff;
    color: #111;
  }
  &.open {
    height: calc(100vw*(369/375));
    border-bottom: 1px solid #f4f4f4;
    & .header::after {
      content: '';
      position: absolute;
      top: calc(100vw*(80/375)); left: 0;
      display: block;
      width: 100%; height: 1px;
      background-color: #f4f4f4;
    }
  }
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; height: calc(100vw*(70/375));
    margin: 0 auto;
    padding: 0 calc(100vw*(20/375));
  }
  & .logo {
    width: calc(100vw*(102/375)); height: calc(100vw*(35/375));
    cursor: pointer;
    & img {
      width: 100%; height: 100%;
    }
  }
  & .btn_gnb {
    width: calc(100vw*(28/375)); height: calc(100vw*(28/375));
    & img {width: 100%; height: 100%;}
  }
`;

const Gnb = styled.div`
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100vh;
  background-color: #fff;
  color: #111;
  z-index: 20;
  overflow: hidden;
  & .gnb_inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: calc(100vw*(20/375)) calc(100vw*(20/375)) 0;
    & .btn_counsel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; height: calc(100vw*(50/375));
      background-color: #DA250B;
      font-size: calc(100vw*(16/375));
      font-weight: bold;
      color: #fff;
      margin-top: calc(100vw*(70/375));
      & img {width: calc(100vw*(28/375)); height: calc(100vw*(28/375)); margin-right: calc(100vw*(7/375));}
    }
    & .gnb {
      display: flex;
      flex-direction: column;
      gap: calc(100vw*(30/375));
      margin-top: calc(100vw*(40/375));
      & li {
        position: relative;
        width: 100%;
        text-align: left;
        & span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%; height: 100%;
          font-size: calc(100vw*(20/375));
          line-height: calc(100vw*(24/375));
          font-weight: 600;
          cursor: pointer;
          &.active {
            color: #DA250B;
          }
        }
        & .lnb {
          display: flex;
          flex-direction: column;
          gap: calc(100vw*(24/375));
          width: 100%;
          padding: calc(100vw*(30/375)) 0 calc(100vw*(30/375)) calc(100vw*(18/375));
          border-bottom: 1px solid #F5F5F5;
          & li {
            position: relative;
            cursor: pointer;
            & span {
              display: block;
              font-size: calc(100vw*(16/375));
              line-height: calc(100vw*(19/375));
              text-align: left;
            }
          }
        }
        &:last-child {
          & .lnb {
            padding-bottom: 0;
            border: none;
          }
        }
      }
    }
    & .gnb_bottom {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: calc(100vw*(8/375)) calc(100vw*(6/375));
      margin-top: calc(100vw*(66/375));
      padding: calc(100vw*(30/375)) 0;
      color: #888;
      &::after {
        content: '';
        position: absolute;
        left: -calc(100vw*(20/375)); top: 0;
        display: block;
        width: calc(100% + calc(100vw*(40/375))); height: 1px;
        background-color: #F5F5F5;
      }
      & .tit {
        width: 100%;
        font-size: calc(100vw*(16/375));
        line-height: calc(100vw*(19/375));
        font-weight: bold;
      }
      & .dec {
        display: inline-block;
        font-size: calc(100vw*(14/375));
        line-height: calc(100vw*(17/375));
        font-weight: 500;
        margin-top: calc(100vw*(8/375));
      }
    }
  }
`;



export {
  Header, Gnb
}

export default {
  Header, Gnb
}