import ArticleHeading from "components/layout/ArticleHeading";
import { ColumnList } from "components/layout/ColumnList";
import SwiperWrap from "components/layout/SwiperWrap";
import { EX_SWIPER_LIST, NEWS_COLUMN_LIST } from "constant";
import { useBoard } from "hooks";
import React, { useState } from "react";

const IntroNews = () => {
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: 1,
    parent_category_info_id: "",
    category_info_id: "",
    type: 5,
    recordSize: 10,
  });

  const boardInfo = useBoard({
    listOption: filterInfo
  });

  const boardBestInfo = useBoard({
    listOption: {
      page: 1,
      pageSize: 1,
      parent_category_info_id: "",
      category_info_id: "",
      hold_yn:"Y",
      type: 5,
      recordSize: 10,
    }
  });

  const bestList = boardBestInfo.listQuery?.data?.list || [];
  const list = boardInfo.listQuery?.data?.list || [];

  return (
    <section className="section expert column full">
      <div className="expert-column-wrap">
        <div className="column-inner">
          <div className="top">
            <ArticleHeading title={"온강 주요 소식"} />
            <SwiperWrap list={bestList || EX_SWIPER_LIST} link={"/intro/news/detail/"}/>
          </div>
          <div className="bottom">
            <ColumnList 
              list={list || NEWS_COLUMN_LIST} 
              link={"/intro/news/detail/"} 
              pagination={boardInfo.listQuery?.data?.pagination}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroNews;
