import product from "./product";
import board from "./board";
import category from "./category";
import site from "./site";
import inquiry from "./inquiry";

export default {
  product,
  board,
  category,
  site,
  inquiry,
};

export { product, board, category, site, inquiry };
