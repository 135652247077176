import styled from "styled-components";

// img
import CounselBg from "resources/image/counsel/counsel_bg.jpg";
import MbCounselBg from "resources/image/counsel/mb_counsel_banner_bg.jpg";


const CounselWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; height: 890px;
  margin-bottom: 50px;
  background: url(${CounselBg}) no-repeat center/1920px;

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(890/1520));
    margin-bottom: calc(100vw*(50/1520));
    background-size: calc(100vw*(1920/1520));
  }

  /* 테블릿 */
  @media (max-width: 1024px) {
    height: calc(100vw*(642/375));
    margin-bottom: calc(100vw*(130/375));
    padding: 0 calc(100vw*(20/375));
    background-image: url(${MbCounselBg});
    background-size: 100%;
  }
`;

export { CounselWrap };

export default { CounselWrap };
