import { Counsel } from "components/layout";
import Styles from './styles';


function MainCounsel() {

  return (
    <Styles.MainCounsel>
      <div className="counsel_inner">
        <div className="counsel_title">
          <h2 className="tit">혼자 고민하지 마시고<br/><strong>온강과 함께</strong> 해결하세요</h2>
          <p className="text">마약 사건 상담 내용은 <br/><span>100% 비밀</span> 보장됩니다.</p>
          <p className="dec">
            변호사법 제26조(비밀유지의무 등)<br/>
            변호사 또는 변호사이었던 자는 그 직무상 알게 된 비밀을<br/>
            누설하여서는 아니 된다.
          </p>
        </div>
        <Counsel />
      </div>
    </Styles.MainCounsel>
  );
}

export default MainCounsel;