import React, { useState } from "react";
import styled from "styled-components";
import ImgBox from "../ImgBox";

import { NavLink } from "react-router-dom";
import utils from "utils";
import { IMG } from "constant";
import Pagination from "../Pagination";
import { IMAGEPATH } from "service/connector";

export const ColumnList = ({ list, pagination,  link }) => {
  const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxCount, setMaxCount] = useState(0);
  const pageSize = 5;
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: pageSize,
    recordSize: 6,
    type: "0",
    start_date: "",
    end_date: "",
  });


  return (
    <ColumnListStyle className="column-list-container">
      <ul className="column-list">
        {list.map((listItem, listIdx) => {
          return (
            <li className="column-item" key={listIdx}>
              <NavLink className={"link"} to={link + listItem.id}>
                <div className="img-wrap">
                  <ImgBox src={(listItem.image && IMAGEPATH + listItem.image) || IMG.ExImg} type={listItem.keyword || listItem.type} />
                </div>
                <div className="text-wrap">
                  {listItem.created_at && <p className="date">{utils.date.getDateType(listItem.created_at)}</p>}
                  <p className="title">{listItem.title}</p>
                  <p className="sub-title">{listItem?.description_text}</p>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
      {pagination?.page_list !== 0 && 
        <Pagination 
          page={pagination?.page}
          list={pagination?.page_list}
          maxPage={pagination?.totalPageCount}
        />
      }
    </ColumnListStyle>
  );
};

const ColumnListStyle = styled.div`
  &.column-list-container {
    padding-top: 80px;
    .column-list {
      margin-bottom: 70px;
    }

    .column-item {
      border-bottom: 2px solid #c4c4c4;
      padding-bottom: 30px;
      margin-bottom: 30px;
      & .link {
        width: 100%;
        display: flex;
        gap: 20px;
      }

      & .img-box {
        flex: 1;
        max-width: 297px;
        margin-bottom: 0;
      }

      & .text-wrap {
        flex: 1;
        .date {
          font-size: 14px;
          color: #888;
          line-height: 17px;
          margin-bottom: 5px;
        }
        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .sub-title {
          font-size: 14px;
          line-height: 140%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #888;
        }
      }
    }
    .column-item:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1520px) {
    &.column-list-container {
      padding-top: calc(100vw * (80 / 1520));
      .column-list {
        margin-bottom: calc(100vw * (70 / 1520));
      }
      .column-item {
        padding-bottom: calc(100vw * (30 / 1520));
        margin-bottom: calc(100vw * (30 / 1520));
        & .link {
          gap: calc(100vw * (20 / 1520));
        }

        & .img-box {
          max-width: calc(100vw * (297 / 1520));
        }

        & .text-wrap {
          .date {
            font-size: calc(100vw * (14 / 1520));
            line-height: calc(100vw * (17 / 1520));
            margin-bottom: calc(100vw * (5 / 1520));
          }
          .title {
            font-size: calc(100vw * (32 / 1520));
            line-height: calc(100vw * (38 / 1520));
            margin-bottom: calc(100vw * (20 / 1520));
          }
          .sub-title {
            font-size: calc(100vw * (14 / 1520));
          }
        }
      }
      .column-item:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    &.column-list-container {
      padding: 0 calc(100vw * (20 / 375));
      .column-list {
        margin-bottom: calc(100vw * (40 / 375));
      }
      .column-item {
        padding-bottom: calc(100vw * (30 / 375));
        margin-bottom: calc(100vw * (30 / 375));
        & .link {
          flex-direction: column;
          gap: calc(100vw * (15 / 375));
        }

        & .img-box {
          max-width: 100%;
        }

        & .text-wrap {
          .date {
            font-size: calc(100vw * (14 / 375));
            line-height: calc(100vw * (17 / 375));
            margin-bottom: calc(100vw * (5 / 375));
          }
          .title {
            font-size: calc(100vw * (20 / 375));
            line-height: calc(100vw * (30 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }
          .sub-title {
            font-size: calc(100vw * (14 / 375));
          }
        }
      }
      .column-item:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
