import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.connectFetchController(
    `site?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

export { getList };

export default {
  getList,
};
