import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { PC, Mobile } from "hooks/useMediaQuery";
import "swiper/css";
import "swiper/css/navigation";
import Styles from './styles';

// img
import IconNext from "resources/image/slide/icon_next.svg";
import IconPrev from "resources/image/slide/icon_prev.svg";
import IconScale from "resources/image/icon/icon_scale.svg";
import HoverImg from "resources/image/slide/hover_img.png";
import utils from "utils";
import { IMAGEPATH } from "service/connector";

function ExampleSlide({list}) {
  const [swiper, setSwiper] = useState(null);
  const exampleList = [
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
    {
      type: "마약",
      title: "원징계처분취소",
      dec: "의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다.",
      date: "2024.01.01",
      img: HoverImg
    },
  ];

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      if (swiper) {
        swiper.update(); // Swiper 수동 업데이트
      }
    }, 100);
    window.addEventListener('resize', handleResize);
  }, []);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };
  
  return (
    <Styles.ExampleSlide>
      <div className="swiper_wrap">
        <PC>
          <Swiper
            className="slider"
            slidesPerView={4}
            loop={true}
            speed={600}
            spaceBetween={20}
            slidesPerGroup={4}
            onSwiper={(e) => {setSwiper(e)}}
          >
            {(list || exampleList)?.map((item, index) => {
              return (
                <SwiperSlide key={"exampleList" + index}>
                  <div className="example_slide">
                    <div className="example_inner">
                      <div className="example_tit">
                        <img src={IconScale} alt={item.type} />
                        <span>{ item.subtitle }</span>
                      </div>
                      <div className="example_text">
                        <p className="tit">{ item.title }</p>
                        <p className="dec">{ item.description_text?.slice(0, 100) }</p>
                        <span className="date">종결일 <span>{ utils.date.getDateType(item.ended_at,'-') }</span></span>
                      </div>
                      <img src={IMAGEPATH + item.image} className="hover_img" alt={item.type} />
                    </div>
                    <button className="btn_more" onClick={()=>{ window.location.href = `/example/detail/${item.id}`;}}>자세히 보기</button>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper> 
        </PC>
        <Mobile>
          <Swiper
            className="slider"
            slidesPerView={1.14}
            loop={true}
            speed={600}
            spaceBetween={15}
            centeredSlides={true}
            onSwiper={(e) => {setSwiper(e)}}
          >
            {(list || exampleList)?.map((item, index) => {
              return (
                <SwiperSlide key={"exampleList" + index}>
                  <div className="example_slide">
                    <div className="example_inner">
                      <div className="example_tit">
                        <img src={IconScale} alt={item.type} />
                        <span>{ item.type }</span>
                      </div>
                      <div className="example_text">
                        <p className="tit">{ item.title }</p>
                        <p className="dec">{ item.description_text?.slice(0, 100) }</p>
                        <span className="date">종결일 <span>{ item.date }</span></span>
                      </div>
                      <img src={IMAGEPATH + item.image} className="hover_img" alt={item.type} />
                    </div>
                    <button className="btn_more" onClick={()=>{ window.location.href = `/example/detail/${item.id}`;}}>자세히 보기</button>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper> 
        </Mobile>
      </div>
      <button className='slide_btn btn_prev' onClick={handlePrev}>
        <img src={IconPrev} alt="슬라이드 이전" />
      </button>
      <button className='slide_btn btn_next' onClick={handleNext}>
        <img src={IconNext} alt="슬라이드 다음" />
      </button>
    </Styles.ExampleSlide>
  );
}

export { ExampleSlide };

export default ExampleSlide;
