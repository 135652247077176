import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import Styles from './styles';

// img
import IconNext from "resources/image/slide/icon_next.svg";
import IconPrev from "resources/image/slide/icon_prev.svg";
import FixedLawyerImg from "resources/image/main/fixed_lawyer_img.png";
import LawyerImg from "resources/image/main/lawyer_img.jpg";
import { useBoard, useSite } from "hooks";
import { IMAGEPATH } from "service/connector";

function MainLawyerList() {
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: 1,
    parent_category_info_id: "",
    category_info_id: "",
    type: 1,
    recordSize: 10,
  });

  const siteInfo = useSite({
    listOption: {
      board_type: 1,
      content_type: 1,
      type: 2,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  const boardInfo = useBoard({
    listOption: filterInfo
  });

  const subList = boardInfo.listQuery?.data?.list || [];

  const fixLawyerList = [
    { name: "배한진", dec: '검사출신', img: FixedLawyerImg },
    { name: "심강현", dec: '검사출신', img: FixedLawyerImg },
    { name: "성인욱", dec: '검사출신', img: FixedLawyerImg },
    { name: "이고은", dec: '검사출신', img: FixedLawyerImg },
    { name: "하나", dec: '검사출신', img: FixedLawyerImg },
  ];
  const lawyerList = [
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
    { name: "배한진", img: LawyerImg },
  ];

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };

  useEffect(()=>{
    SwiperCore?.use([Autoplay]);
  },[])

  return (
    <Styles.MainLawyerList>
      <div className="lawyer_inner">
        <h2>당신과 끝까지 함께 할<br/><strong>온강의 마약 TF팀</strong></h2>
        <div className="fixed_list">
          {(list || fixLawyerList)?.map((item, index) => {
            return (
              <div className="fixed_box" key={"fixLawyerList" + index} onClick={()=>{ window.location.href = `/intro/expert/detail/${item.id}`;}}>
                <div className="fixed_img">
                  <img src={IMAGEPATH + item.image} alt={item.name} />
                </div> 
                <p className="name">{ item.title } 변호사</p>
                <span className="dec">{ item.subtitle }</span>
                <button className="btn_more" onClick={()=>{ window.location.href = `/intro/expert/detail/${item.id}`;}}>자세히 보기</button>
              </div>
            )
          })}
        </div>
        <div className="swiper_wrap">
        {(subList || lawyerList).length > 0  && 
            <Swiper
              modules={[Autoplay]}
              className="slider"
              slidesPerView={'auto'}
              centeredSlides={false}
              loop={true}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
              spaceBetween={10}
              onSwiper={(e) => {setSwiper(e)}}
              onSlideChange={(e) => {
                setIsBeginning(e.isBeginning);
                setIsEnd(e.isEnd);
              }}
            >
            {(subList || lawyerList)?.map((item, index) => {
              return (
                <SwiperSlide key={"lawyerList" + index}>
                  <div className="lawyer_slide" onClick={()=>{ window.location.href = `/intro/expert/detail/${item.id}`;}}>
                    <img src={IMAGEPATH + item.image} className="lawyer_img" alt={item.name} />
                  </div>
                </SwiperSlide>
              )
            })}
            </Swiper>
          }
          <button className={'slide_btn btn_prev' + (isBeginning ? ' disabled' : '')} onClick={handlePrev}>
            <img src={IconPrev} alt="슬라이드 이전" />
          </button>
          <button className={'slide_btn btn_next' + (isEnd ? ' disabled' : '')} onClick={handleNext}>
            <img src={IconNext} alt="슬라이드 다음" />
          </button>
        </div>
      </div>
    </Styles.MainLawyerList>
  );
}

export default MainLawyerList;