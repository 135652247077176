import styled from "styled-components";

const MainExample = styled.div`
  position: relative;
  width: 1500px;
  padding-top: 100px;
  margin: 0 auto 100px;
  & h2 {
    font-size: 56px;
    font-weight: 800;
    letter-spacing: -0.5px;
    text-align: center;
  }
  & p {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
    & span {
      color: #da250b;
    }
  }
  & > .btn_more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 154px;
    height: 45px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: bold;
    padding: 0 30px;
    border-radius: 30px;
    border: 1px solid #111;
    transition: 0.5s;
    z-index: 1;
    & img {
      width: 18px;
      height: 18px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      & img {
        animation: rotate 0.8s ease-in-out forwards;
      }
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw*(1500/1520));
    padding-top: calc(100vw*(100/1520));
    margin: 0 auto calc(100vw*(100/1520));
    & h2 {
      font-size: calc(100vw*(56/1520));
    }
    & p {
      font-size: calc(100vw*(26/1520));
      font-weight: 500;
      line-height: calc(100vw*(36/1520));
      margin-top: calc(100vw*(20/1520));
    }
    & > .btn_more {
      width: calc(100vw*(154/1520)); height: calc(100vw*(45/1520));
      font-size: calc(100vw*(18/1520));
      padding: 0 calc(100vw*(30/1520));
      border-radius: calc(100vw*(30/1520));
      & img {
        width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    width: 100%;
    padding-top: calc(100vw*(80/375));
    margin: 0 auto calc(100vw*(84/375));
    & h2 {
      font-size: calc(100vw*(30/375));
    }
    & p {
      font-size: calc(100vw*(18/375));
      line-height: calc(100vw*(25/375));
      margin-top: calc(100vw*(15/375));
    }
    & > .btn_more {
      width: calc(100vw*(119/375)); height: calc(100vw*(39/375));
      font-size: calc(100vw*(16/375));
      padding: 0 calc(100vw*(20/375));
      border-radius: calc(100vw*(40/375));
      & img {
        width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
      }
    }
  }
`;

export { MainExample };

export default {
  MainExample,
};
