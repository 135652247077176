import { useState, useEffect } from "react";

import Styles from './styles';

// img
import IconMap from "resources/image/icon/icon_map.svg";
import IconTel from "resources/image/icon/icon_tel.svg";
import IconTime from "resources/image/icon/icon_time.svg";
import IconMail from "resources/image/icon/icon_mail.svg";
import MapImg from "resources/image/main/naver_map.jpg";

function MainDirection() {
  const [tab, setTab] = useState(0);

  return (
    <Styles.MainDirection>
      <div className="map_inner">
        <ul className="tab">
          <li className={tab === 0 ? "active" : ''} onClick={()=>setTab(0)}>주사무소</li>
          <li className={tab === 1 ? "active" : ''} onClick={()=>setTab(1)}>인천분사무소</li>
          <li className={tab === 2 ? "active" : ''} onClick={()=>setTab(2)}>안산분사무소</li>
        </ul>
        <div className="map_content">
          <img src={MapImg} className="map" alt="" />
          <div className="map_info">
            <div className="map_box">
              <img src={IconMap} className="map_icon" alt="" />
              <div className="map_text">
                <p className="tit">주소</p>
                {tab === 0 &&
                  <p className="dec">
                    서울 서초구 서초대로 254, 오퓨런스빌딩 3층 302호<br/>
                    (서초역 1번 출구 3분 거리 투썸플레이스 건물 3층)
                  </p>
                }
                {tab === 1 && <p className="dec">인천광역시 미추홀구 소성로185번길 23, 육삼빌딩 5층</p>}
                {tab === 2 && <p className="dec">경기도 안산시 단원구 광덕서로 62, 601호</p>}
                
              </div>
            </div>
            <div className="map_box">
              <img src={IconTel} className="map_icon" alt="" />
              <div className="map_text">
                <p className="tit">대표전화</p>
                <p className="dec"><a href="tel:+1670-5901">1670-5901</a></p>
              </div>
            </div>
            <div className="map_box">
              <img src={IconTime} className="map_icon" alt="" />
              <div className="map_text">
                <p className="tit">업무시간</p>
                <p className="dec">오전 8시~오후 10시<br/>
                (24시간 연중무휴 긴급대응 가능)</p>
              </div>
            </div>
            <div className="map_box">
              <img src={IconMail} className="map_icon" alt="" />
              <div className="map_text">
                <p className="tit">메일</p>
                <p className="dec">ongang@ongang.kr</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles.MainDirection>
  );
}

export default MainDirection;