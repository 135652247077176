import { Banner, Counsel } from "components/layout";
import Styles from './styles';

// img
import BannerBg from "resources/image/counsel/counsel_banner_bg.jpg";

export default function CounselPage() {
  return (
    <>
      <Banner title={"1:1 비밀상담<br/>신청하기"} sub={"변호사법 제26조(비밀유지의무 등)<br class='pcBr'/><br class='mbBr'/>변호사 또는 변호사이었던 자는 그 직무상 알게 <br class='mbBr'/>된 비밀을 누설하여서는 아니 된다."} img={BannerBg} />
      <Styles.CounselWrap>
        <Counsel/>
      </Styles.CounselWrap>
    </>
  );
}
