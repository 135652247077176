import Styles from "./styles";

//img
import IconClose from "resources/image/icon/icon_close.svg";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="wrap">
        <button type="button" className="closeButton" onClick={modalClose}>
          닫기
          <img src={IconClose} alt="close" />
        </button>
        <h2 className="title">
          {modalOption.title}
        </h2>

        <div className="inner">
          <p className="text" dangerouslySetInnerHTML={{ __html: modalOption.dec }} />
        </div>
      </div>

      <div className="overlay" onClick={modalClose}></div>
    </Styles.Container>
  );
}

export { DefaultModal };

export default DefaultModal;
