import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > button:first-child {
    margin-right: 20px;
  }
  & > button:last-child {
    margin-left: 20px;
  }

  & .iconDoubleArrow, .iconArrow {
    width: 24px;
    height: 24px;
  }

  & .paginationList {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 30px;

    & button {
      position: relative;
      width: 22px;
      height: 22px;
      font-size: 18px;
      font-weight: 500;

      &.on {
        color: #DA250B;
      }
    }
  }
`;

export { Container };

export default { Container };
