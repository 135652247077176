import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { ICON, IMG } from "constant";

const FloatHeading = ({ data, customClass }) => {
  const { pathname } = useLocation();
  const [currentHeading, setCurrentHeading] = useState(pathname.split("/")[2]); // TODO;; 리펙토링 필요

  const [title, setTitle] = useState(data.HEAD.title[currentHeading]); // 상단 타이틀
  const [tabTitle, setTabTitle] = useState(""); // 상단 탭 타이틀
  const [subTitle, setSubTitle] = useState(data.HEAD.subTitle[currentHeading]); // 상단 서브 타이틀
  const [subject, setSubject] = useState(data.HEAD.subject); // 상단 주제

  const [isMbClick, setIsMbClick] = useState(false);

  useEffect(() => {}, [isMbClick]);

  const getTabTitle = () => {
    data.TAB_LIST.forEach((item) => {
      if (item.tabLink === currentHeading) {
        setTabTitle(item.label);
      }
    });
  };

  useEffect(() => {
    const headingPathname = pathname.split("/")[2];
    setCurrentHeading(headingPathname);
    getTabTitle();
  }, [pathname]);

  useEffect(() => {
    setTitle(data?.HEAD.title[currentHeading]);
    setSubTitle(data.HEAD.subTitle[currentHeading]);
    setSubject(data.HEAD.subject);
    getTabTitle();
    setIsMbClick(false); // mobile
  }, [currentHeading]);

  return (
    <section className={`section contents-header full ${customClass}`}>
      <div className={`banner-main ${currentHeading}`}>
        <div className="banner-main__inner">
          <div className="text-wrap">
            <p className="tit">
              <img className="line-img" src={ICON.ICON_LINE} alt="line img" />
              <span className="heading-text">
                <span className="title">{title}</span>
                <span className="sub-title">{subTitle}</span>
              </span>
            </p>
          </div>
          <div className="history-navi-wrap">
            <div className="history-box">
              <ul className="history-list">
                <li className="history-item">
                  <img src={ICON.ICON_HOME} alt="" />
                </li>
                <li className="history-item">
                  <img src={ICON.ICON_RIGHT} alt="" />
                </li>
                <li className="history-item">{subject}</li>
                <li className="history-item">
                  <img src={ICON.ICON_RIGHT} alt="" />
                </li>
                <li className="history-item">{tabTitle}</li>
              </ul>
            </div>
            <div className="navi-box">
              {/*S:: mobile */}
              <div
                className="tit"
                onClick={() => {
                  setIsMbClick((val) => !val);
                }}
              >
                {tabTitle}
                <img className={`img-arrow ${isMbClick ? "active" : ""}`} src={ICON.ICON_RIGHT} alt="" />
              </div>
              {/*E:: mobile */}
              <ul className={`navi-list ${isMbClick ? "mb-active" : ""}`}>
                {data.TAB_LIST.map((tab_item) => {
                  const isActive = pathname.includes(tab_item.tabLink);
                  return (
                    <li className="navi-item" key={tab_item.id + Math.random()}>
                      <Link className={`link ${isActive ? "active" : ""}`} to={`/${data?.URL}/${tab_item.tabLink}`}>
                        {tab_item.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FloatHeading;
