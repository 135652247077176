import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FloatHeading from "components/layout/FloatHeading";

import { STORY_HEADING_DATA } from "constant";
import StoryReview from "./components/StoryReview";
import StoryYoutube from "./components/StoryYoutube";
import StoryColumn from "./components/StoryColumn";

const StoryPage = () => {
  const { contents } = useParams();
  const renderComponents = {
    review: <StoryReview />, // 6-1 의뢰인 후기
    youtube: <StoryYoutube />, // 6-2 유튜브
    column: <StoryColumn />, // 6-3 칼럼
  };

  return (
    <div className="intro page">
      <div className="inner">
        <FloatHeading data={STORY_HEADING_DATA} />
        {renderComponents[contents] || <div>Error!</div>}
      </div>
    </div>
  );
};

export default StoryPage;
