import Styles from "./styles";

function Radio({
  id,
  name,
  labelText,
  onChange,
  checked,
  isDisabled,
}) {
  return (
    <>
      <Styles.Input
        type="radio"
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={isDisabled}
      />

      <Styles.Label htmlFor={id}>
        <span className="radio"></span>

        <span className="radioText">{labelText}</span>
      </Styles.Label>
    </>
  );
}

export { Radio };

export default Radio;
