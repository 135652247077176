import ArticleHeading from "components/layout/ArticleHeading";
import { ColumnList } from "components/layout/ColumnList";
import SearchInput from "components/layout/SearchInput";
import SwiperWrap from "components/layout/SwiperWrap";
import { STORY_COLUMN_LIST_02, STORY_EX_SWIPER_LIST } from "constant";
import { useBoard, useSite } from "hooks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StoryColumn = () => {
  const [isList, setIsList] = useState(false);
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: 1,
    parent_category_info_id: "",
    category_info_id: "55",
    type: 6,
    recordSize: 10,
  });

  const boardInfo = useBoard({
    listOption: filterInfo
  });

  const siteInfo = useSite({
    listOption: {
      board_type: 6,
      content_type: 6,
      type: 2,
    },
  });

  const bestList = siteInfo.listQuery?.data?.list || [];
  const list = boardInfo.listQuery?.data?.list || [];

  useEffect(() => {
    if (STORY_COLUMN_LIST_02.length === 0) setIsList(true);
    if (STORY_COLUMN_LIST_02.length !== 0) setIsList(false);
  }, []);

  return (
    <section className="section story column full">
      <div className="story-column-wrap">
        <div className="column-inner">
          <TopStyle className="top" isList={isList}>
            <ArticleHeading title={"필독 꿀팁"} />
            {STORY_COLUMN_LIST_02.length !== 0 && <SwiperWrap list={bestList} link={`/story/column/detail/`}/>}
          </TopStyle>
          <div className="bottom">
            <SearchInput placeholder={"칼럼 검색"} />
            {STORY_COLUMN_LIST_02.length !== 0 && <ColumnList list={list} pagination={boardInfo.listQuery?.data?.pagination} link={`/story/column/detail/`}/>}
            {STORY_COLUMN_LIST_02.length === 0 && <div className="empty-list">검색결과가 없습니다.</div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoryColumn;

const TopStyle = styled.div`
  ${(props) => props.isList && `border:none !important; margin-bottom: 0 !important;`};
`;
