import { MainBanner, MainExample, MainPressLawyer, MainLawyerList, MainProcess, MainReview, MainYoutube, MainCounsel, MainDirection } from "./components";

export default function MainPage() {
  return (
    <>
      <MainBanner />
      <MainExample />
      <MainPressLawyer />
      <MainLawyerList />
      <MainProcess />
      <MainReview />
      <MainYoutube />
      <MainCounsel />
      <MainDirection />
    </>
  );
}
