import SearchInput from "components/layout/SearchInput";
import SearchResultList from "components/layout/SearchResultList";
import { EXPERT_DATA, ICON } from "constant";
import { useBoard } from "hooks";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const IntroExpert = () => {

  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: 1,
    parent_category_info_id: "",
    category_info_id: "1",
    type: 1,
    recordSize: 10,
  });

  const boardInfo = useBoard({
    listOption: filterInfo
  });

  const list = boardInfo.listQuery?.data?.list || [];

  return (
    <section className="section expert">
      <div className="expert-wrap">
        <div className="expert-search">
          <SearchInput placeholder={"전문가 검색"} />
        </div>
        <SearchResultList 
          data={list || EXPERT_DATA} 
          link={"/intro/expert/detail/"} 
          pagination={boardInfo.listQuery?.data?.pagination}
        />
      </div>
    </section>
  );
};

export default IntroExpert;
