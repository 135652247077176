import React, { useEffect, useState } from "react";

import FloatHeading from "components/layout/FloatHeading";

import { SITUATION_HEADING_DATA } from "constant";
import Apply from "./components/Apply";
import Focus from "./components/Focus";
import Process from "./components/Process";
import Dialog from "./components/Dialog";

const SituationPage = () => {
  return (
    <div className="intro page">
      <div className="inner">
        <FloatHeading data={SITUATION_HEADING_DATA} customClass={"situation"} />
        <Apply />
        <Focus />
        <Process />
        <Dialog />
      </div>
    </div>
  );
};

export default SituationPage;
