import styled, { css } from 'styled-components';

// img
import ProcessBg from "resources/image/main/process_bg.jpg";
import MbProcessBg from "resources/image/main/mb_process_bg.jpg";

const MainYoutube = styled.div`
  position: relative;
  width: 100%;
  padding: 92px 0;
  background: #000 url(${ProcessBg}) no-repeat top center /1920px;
  overflow: hidden;
  & .youtube_inner {
    width: 1500px;
    margin: 0 auto;
    & .youtube_title {
      color: #fff;
      text-align: center;
      & .tit {
        font-size: 56px;
        line-height: 72px;
        text-align: center;
        font-weight: 800;
        letter-spacing: -0.5px;
      }
      & .dec {
        font-size: 20px;
        line-height: 24px;
        margin-top: 10px;
      }
      & .youtube {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        & p {
          font-size: 26px;
          line-height: 31px;
          font-weight: bold;
        }
        & img {
          width: 37px;
          margin-right: 10px;
        }
        & span {
          color: #DA250B;
        }
      }
    }
    & .youtube_list {
      position: relative;
      width: 1260px;
      margin: 50px auto 0;
      & li {
        width: calc(100% - 862px); height: 225px;
        margin-left: auto;
        &.main {
          position: absolute;
          left: 0; top: 0;
          width: 838px; height: 474px;
          margin-right: 24px;
        }
        & iframe {
          display: block;
          width: 100%; height: 100%;
        }
        &:last-child {
          margin-top: 24px;
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw*(92/1520)) 0;
    background-size: calc(100vw*(1920/1520));
    & .youtube_inner {
      width: calc(100vw*(1500/1520));
      & .youtube_title {
        & .tit {
          font-size: calc(100vw*(56/1520));
          line-height: calc(100vw*(72/1520));
        }
        & .dec {
          font-size: calc(100vw*(20/1520));
          line-height: calc(100vw*(24/1520));
          margin-top: calc(100vw*(10/1520));
        }
        & .youtube {
          margin-top: calc(100vw*(10/1520));
          & p {
            font-size: calc(100vw*(26/1520));
            line-height: calc(100vw*(31/1520));
          }
          & img {
            width: calc(100vw*(37/1520));
            margin-right: calc(100vw*(10/1520));
          }
        }
      }
      & .youtube_list {
        width: calc(100vw*(1260/1520));
        margin: calc(100vw*(50/1520)) auto 0;
        & li {
          width: calc(100% - calc(100vw*(862/1520))); height: calc(100vw*(225/1520));
          &.main {
            width: calc(100vw*(838/1520)); height: calc(100vw*(474/1520));
            margin-right: calc(100vw*(24/1520));
          }
          &:last-child {
            margin-top: calc(100vw*(24/1520));
          }
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw*(92/375)) 0;
    background-size: 100%;
    background-image: url(${MbProcessBg});
    & .youtube_inner {
      width: 100%;
      & .youtube_title {
        & .tit {
          font-size: calc(100vw*(36/375));
          line-height: calc(100vw*(47/375));
        }
        & .dec {
          font-size: calc(100vw*(16/375));
          line-height: calc(100vw*(19/375));
          margin-top: calc(100vw*(10/375));
        }
        & .youtube {
          margin-top: calc(100vw*(10/375));
          & p {
            font-size: calc(100vw*(20/375));
            line-height: calc(100vw*(24/375));
          }
          & img {
            width: calc(100vw*(37/375));
            margin-right: calc(100vw*(10/375));
          }
        }
      }
      & .youtube_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: calc(100vw*(20/375)) 0;
        margin: calc(100vw*(50/375)) auto 0;
        padding: 0 calc(100vw*(20/375));
        & li {
          width: calc(100vw*(160/375)); height: calc(100vw*(91/375));
          margin: 0;
          &.main {
            position: initial;
            width: 100%; height: calc(100vw*(190/375));
            margin-right: 0;
          }
          &:last-child {
            margin-top: 0;
          }
        }
      }
    }
  }
`;




export {
  MainYoutube
}

export default {
  MainYoutube
}