import { IMG, SITUATION_APPLY } from "constant";
import { Mobile, PC } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Apply = () => {
  const { pathname } = useLocation();
  const [currentHeading, setCurrentHeading] = useState(pathname.split("/")[2]); // TODO;; 리펙토링 필요
  const [data, setData] = useState(SITUATION_APPLY[currentHeading]);

  useEffect(() => {
    setData(SITUATION_APPLY[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
  }, [currentHeading]);
  useEffect(() => {
    setData(SITUATION_APPLY[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
  }, [pathname]);
  return (
    <section className="section situation full">
      <div className="apply">
        <div className="apply-inner">
          <div className="contents-wrap">
            <div className="info-box">
              <div className="text-wrap">
                <p className="title">{data.title}</p>
                {data.subTitle.map((item) => {
                  return (
                    <p className="sub-title" key={item.text + Math.random()}>
                      {item.text}
                    </p>
                  );
                })}
                <div className="step-box">
                  {data.step.map((stepItem, stepIdx) => {
                    return (
                      <p className="step" key={stepItem.text + Math.random()}>
                        <span className="step-count">Q{stepIdx + 1}</span>
                        <span className="text">{stepItem.text}</span>
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="img-wrap">
                <PC>
                  <img src={data.src} alt="" />
                </PC>
                <Mobile>
                  <img src={data.mbSrc} alt="" />
                </Mobile>
              </div>
            </div>

            <div className="bottom-info">
              <p className="title">
                마약검사출신 <span className="light">변호사</span> 직통 상담
              </p>
              <p className="sub-title">비밀 상담 서비스를 통해 궁금증을 해결해 보세요.</p>
              <button className="btn">비밀 상담 신청하기</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apply;
