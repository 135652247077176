import Styles from "./styles";

function Input({
  placeholder,
  value,
  onChange,
  type
}) {
  return (
    <Styles.Input
      type={type || "text"}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete="off"/>
  );
}

export { Input };

export default Input;
