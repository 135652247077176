import { useState, useEffect } from "react";
import { ExampleSlide } from "components/layout";
import Styles from './styles';

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";
import HoverImg from "resources/image/slide/hover_img.png";
import { useSite } from "hooks";

function MainExample() {


  const siteInfo = useSite({
    listOption: {
      board_type: 11,
      content_type: 2,
      type: 2,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  return (
    <Styles.MainExample>
      <h2>주요 성공사례</h2>
      <p>온강이어야 하는 이유,<br/><span>실제 성공사례</span>로 확인하세요</p>
      <ExampleSlide list={list}/>
      <button className='btn_more' onClick={()=>{ window.location.href = "/example" }}>더보기<img src={IconPlus} alt="more view" /></button>
    </Styles.MainExample>
  );
}

export default MainExample;