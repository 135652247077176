import { PaginationUI } from "components/layout/PaginationUI";
import { ICON, IMG } from "constant";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "../Pagination";
import { IMAGEPATH } from "service/connector";

const SearchResultItem = ({ item, idx, link }) => {
  const [hoverText, setHoverText] = useState("");

  const onMouseEnter = () => {
    setHoverText("자세히 보기");
  };
  const onMouseLeave = (text) => {
    setHoverText(text);
  };

  return (
    <li className="expert-item" onMouseEnter={onMouseEnter} onMouseLeave={() => onMouseLeave(item.subtitle)}>
      <NavLink to={`${link}${item.id}`} className="link">
        <div className="text-wrap">
          <div className="logo-box">
            <img src={IMAGEPATH + item.extra_image || ICON.ICON_WHITE_LOGO} alt="" />
          </div>
          <div className="text-box">
            <p className="title">
              <span className="name">{item.title}</span>&nbsp;변호사
            </p>
            <p className="sub-title">{hoverText == "" ? item.subtitle : hoverText}</p>
          </div>
        </div>
        <img src={IMAGEPATH + item.image || IMG.ROYAL_EX} alt="" />
      </NavLink>
    </li>
  );
};

const SearchResultList = ({ data,link, pagination }) => {
  const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxCount, setMaxCount] = useState(0);
  const pageSize = 5;
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: pageSize,
    recordSize: 6,
    type: "0",
    start_date: "",
    end_date: "",
  });

  return (
    <div className="expert-container">
      {data.length === 0 && <div className="empty-list">검색결과가 없습니다.</div>}
      {data.length > 0 && (
        <>
          <ul className="expert-list">
            {data.map((expertItem, expertIdx) => {
              return (
                <React.Fragment key={expertItem.id}>
                  <SearchResultItem item={expertItem} idx={expertIdx} link={link} />
                </React.Fragment>
              );
            })}
          </ul>

          <Pagination
            page={pagination?.page}
            list={pagination?.page_list}
            maxPage={pagination?.totalPageCount}
          />
        </>
      )}
    </div>
  );
};

export default SearchResultList;
