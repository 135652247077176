import Styles from "./styles";

function Checkbox({ id, labelText, onChange, checked, onClick }) {
  return (
    <>
      <Styles.Input
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={checked}
        onClick={onClick}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkbox"></span>
        <span className="checkboxText">{labelText}</span>
      </Styles.Label>
    </>
  );
}

export { Checkbox };

export default Checkbox;
