import { useState, useEffect } from "react";
import { ExampleSlide } from "components/layout";
import Styles from './styles';

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";
import { useBoard } from "hooks";

function SubExample() {

  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: 1,
    parent_category_info_id: "33",
    category_info_id: "",
    hold_yn : "Y",
    type: 2,
    recordSize: 10,
  });

  const boardInfo = useBoard({
    listOption: filterInfo
  });
  const list = boardInfo.listQuery?.data?.list || [];

  return (
    <Styles.SubExample>
      <div className="sub_example_inner">
        <h2>이달의 주요 성공사례</h2>
        <ExampleSlide list={list} />
      </div>
    </Styles.SubExample>
  );
}

export default SubExample;