import styled from "styled-components";

//img
import IconRadioOff from "resources/image/icon/icon_radio_off.svg";
import IconRadioOn from "resources/image/icon/icon_radio_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label .radio {
    background: url(${IconRadioOn}) no-repeat center / cover;
  }
`;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  & .radio {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background: url(${IconRadioOff}) no-repeat center / cover;
  }

  & .radioText {
    font-size: 16px;
    font-weight: 500;
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .radio {
      width: calc(100vw*(20/1520));
      height: calc(100vw*(20/1520));
      margin-right: calc(100vw*(5/1520));
    }

    & .radioText {
      font-size: calc(100vw*(16/1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .radio {
      width: calc(100vw*(20/375));
      height: calc(100vw*(20/375));
      margin-right: calc(100vw*(5/375));
    }

    & .radioText {
      font-size: calc(100vw*(14/375));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
