import styled from "styled-components";
import { SwiperWrapStyle } from "../SwiperWrap/styles";

const StorySwiperWrapStyle = styled(SwiperWrapStyle)`
  & {
    .sw-container {
      & .title {
        font-size: 18px;
        min-height: 54px;
      }
    }
    .sw-story {
      border: 1px solid #e4e4e4;
      min-height: 224px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      padding: 60px 15px 0 15px;
      text-align: left;

      &:hover {
        .hover_img {
          display: block;
        }
      }

      & .type-chip {
        font-size: 18px;
        line-height: 21px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        padding: 8px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #da250b;
        position: absolute;
        left: 0;
        top: 0;
      }

      & .hover_img {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 230px;
      }
    }
  }

  @media (max-width: 1520px) {
    & {
      .sw-container {
        & .title {
          font-size: calc(100vw * (18 / 1520));
          min-height: calc(100vw * (54 / 1520));
        }
      }
      .sw-story {
        min-height: calc(100vw * (224 / 1520));
        padding: calc(100vw * (60 / 1520)) calc(100vw * (15 / 1520)) 0 calc(100vw * (15 / 1520));

        & .type-chip {
          font-size: calc(100vw * (18 / 1520));
          line-height: calc(100vw * (21 / 1520));
          padding: calc(100vw * (8 / 1520)) calc(100vw * (30 / 1520));
        }

        & .hover_img {
          max-width: calc(100vw * (230 / 1520));
        }
      }
    }
  }

  @media (max-width: 1024px) {
    & {
      .sw-container {
        & .title {
          font-size: calc(100vw * (18 / 375));
          min-height: calc(100vw * (54 / 375));
          margin-bottom: calc(100vw * (20 / 375));
        }
      }
      .sw-story {
        min-height: calc(100vw * (224 / 375));
        padding: calc(100vw * (60 / 375)) calc(100vw * (15 / 375)) 0 calc(100vw * (15 / 375));

        & .type-chip {
          font-size: calc(100vw * (14 / 375));
          line-height: normal;
          padding: calc(100vw * (6 / 375)) calc(100vw * (30 / 375));
        }

        & .hover_img {
          max-width: calc(100vw * (230 / 375));
        }
      }
    }
  }
`;

export { StorySwiperWrapStyle };

export default { StorySwiperWrapStyle };
