import {
  SubExample,
  SearchList,
} from "./components";
import { Banner } from "components/layout";
import { useDeviceType } from "hooks/useMediaQuery";

// img
import BannerBg from "resources/image/example/example_banner_bg.jpg";
import MbBannerBg from "resources/image/example/mb_example_banner_bg.jpg";

export default function ExamplePage() {
  const deviceType = useDeviceType();

  return (
    <>
      <Banner
        title={"성공사례"}
        sub={"직접 수사하고 재판한 경험을 기반으로,<br class='pcBr'/><br class='mbBr'/>의뢰인의 빠른 일상 복귀를 위해 성실히 <br class='mbBr'/>변론한 결과입니다."}
        img={deviceType === 'pc' ? BannerBg : MbBannerBg}
      />
      <SubExample />
      <SearchList />
    </>
  );
}
