import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import ExamplePage from "./ExamplePage";
import ExampleDetailPage from "./ExampleDetailPage";
import SystemPage from "./SystemPage";
import IntroPage from "./IntroPage";
import IntroExertDetailPage from "./IntroExertDetailPage";
import IntroNewsDetailPage from "./IntroNewsDetailPage";
import StoryPage from "./StoryPage";
import StoryReviewDetailPage from "./StoryReviewDetailPage";
import StoryColumnDetailPage from "./StoryColumnDetailPage";
import SituationPage from "./SituationPage";
import CounselPage from "./CounselPage";

export default {
  ModalPage,
  MainPage,
  ExamplePage,
  ExampleDetailPage,
  SystemPage,
  IntroPage,
  IntroExertDetailPage,
  IntroNewsDetailPage,
  StoryPage,
  StoryReviewDetailPage,
  CounselPage,
  SituationPage,
  CounselPage,
  StoryColumnDetailPage,
};

export { ModalPage, MainPage, ExamplePage, ExampleDetailPage, SystemPage, CounselPage };
