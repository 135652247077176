import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.connectFetchController(
    `board?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

const getGroupList = async (data) => {
  return await connector.authConnector(
    `board/group_category_list?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

const getDetail = async (id) => {
  return await connector.connectFetchController(`board/${id}`, "GET");
};

export { getDetail, getList, getGroupList };

export default {
  getDetail,
  getList,
  getGroupList,
};
