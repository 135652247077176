import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { PC, Mobile } from "hooks/useMediaQuery";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";

// img
import IconNext from "resources/image/slide/icon_next.svg";
import IconPrev from "resources/image/slide/icon_prev.svg";
import { NavLink } from "react-router-dom";
import { IMAGEPATH } from "service/connector";

const BASE_VIEW = 3;
function StorySwiperWrap({ list, perView, perGroup }) {
  const [swiper, setSwiper] = useState(null);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };

  return (
    <Styles.StorySwiperWrapStyle>
      <div className="sw-container">
        <PC>
          <div className="swiper_wrap">
            <Swiper
              className="slider"
              slidesPerView={perView ? perView : BASE_VIEW}
              loop={true}
              speed={600}
              spaceBetween={30}
              slidesPerGroup={perGroup ? perGroup : BASE_VIEW}
              onSwiper={(e) => {
                setSwiper(e);
              }}
            >
              {list?.map((item, index) => {
                return (
                  <SwiperSlide key={"row-swiper" + index}>
                    <NavLink to={"/story/review/detail/" + item.id}>
                      <div className="sw-story">
                        <div className="type-chip">BEST</div>
                        <p className="title">{item.title}</p>
                        <span className="sub-title">{item.description_text}</span>
                        <img className="hover_img" src={(item.image && IMAGEPATH + item.image) || item.hoverImg} />
                      </div>
                    </NavLink>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </PC>
        <Mobile>
          <div className="swiper_wrap">
            <Swiper
              className="slider"
              slidesPerView={1.3}
              loop={true}
              speed={600}
              spaceBetween={15}
              centeredSlides={true}
              slidesPerGroup={1}
              onSwiper={(e) => {
                setSwiper(e);
              }}
            >
              {list?.map((item, index) => {
                return (
                  <SwiperSlide key={"row-swiper" + index}>
                    <NavLink to={"/story/review/detail" + item.id}>
                      <div className="sw-story">
                        <div className="type-chip">BEST</div>
                        <p className="title">{item.title}</p>
                        <span className="sub-title">{item.description_text}</span>
                        <img className="hover_img" src={(item.image && IMAGEPATH + item.image) || item.hoverImg} />
                      </div>
                    </NavLink>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Mobile>

        <button className="slide_btn btn_prev" onClick={handlePrev}>
          <img src={IconPrev} alt="슬라이드 이전" />
        </button>
        <button className="slide_btn btn_next" onClick={handleNext}>
          <img src={IconNext} alt="슬라이드 다음" />
        </button>
      </div>
    </Styles.StorySwiperWrapStyle>
  );
}

export { StorySwiperWrap };

export default StorySwiperWrap;
