import { useEffect } from "react";
import Styles from "./styles";

// img
import arrowDoubleLeft from "resources/image/icon/icon_double_arrow_left.svg";
import arrowLeft from "resources/image/icon/icon_arrow_left.svg";
import arrowDoubleRight from "resources/image/icon/icon_double_arrow_right.svg";
import arrowRight from "resources/image/icon/icon_arrow_right.svg";
import { useNavigate } from "react-router-dom";
import utils from "utils";

function Pagination({
  list, page, maxPage, pagination
}) {
  const navigate = useNavigate();
  const onClickPage = (value) => {
    navigate(utils.etc.getQueryParamUrl({ page : value }))
  };

  const onClickPrev = () => {
    if (page == 1) return;
    navigate(utils.etc.getQueryParamUrl({ page : page - 1}))
  };

  const onClickNext = () => {
    if (page == maxPage) return;
    navigate(utils.etc.getQueryParamUrl({ page : page + 1}))
  };

  // const onClickStart = () => {
  //   let start = 1;
  //   let end = pageSize;
  //   if (maxPage <= pageSize) end = maxPage;

  //   setPageListFunc(start, end);
  //   setPage(1);
  // };

  const onClickEnd = () => {
    if (page == maxPage) return;
    navigate(utils.etc.getQueryParamUrl({ page : maxPage}))
  };

  return (
    <Styles.Container>
      <button
        type="button"
        className="iconDoubleArrow"
        onClick={(e) => {
          onClickPage(1);
        }}
      >
        <img src={arrowDoubleLeft} alt="arrowImage" />
      </button>
      <button
        type="button"
        className="iconArrow"
        onClick={(e) => {
          onClickPrev(e);
        }}
      >
        <img src={arrowLeft} alt="arrowImage" />
      </button>
      <div className="paginationList">
        {(list ? list : []).map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? "on" : ""}
              onClick={() => {
                onClickPage(item);
              }}
              key={index}
            >
              {item}
            </button>
          );
        })}
      </div>
      <button
        type="button"
        className="iconArrow"
        onClick={(e) => {
          onClickNext(e);
        }}
      >
        <img src={arrowRight} alt="arrowImage" />
      </button>
      <button
        type="button"
        className="iconDoubleArrow"
        onClick={(e) => {
          onClickEnd(e);
        }}
      >
        <img src={arrowDoubleRight} alt="arrowImage" />
      </button>
    </Styles.Container>
  );
}

export { Pagination };

export default Pagination;
