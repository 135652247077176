import Styles from './styles';
import { useDeviceType } from "hooks/useMediaQuery";
import { ICON } from "constant";


function Banner({title,sub,img}) {
  const deviceType = useDeviceType();

  return (
    <Styles.Banner style={{backgroundImage:`url(${img})`}}>
      <div className='banner_inner'>
        <img className="line_img" src={deviceType === "pc" ? ICON.ICON_LINE : ICON.ICON_MBLINE} alt="line img" />
        <div className='bnnr_text'>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          {sub && <p dangerouslySetInnerHTML={{ __html: sub }}/>}
        </div>
      </div>
    </Styles.Banner>
  );
}

export { Banner };

export default Banner;
