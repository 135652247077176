import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Pages from "pages";

export default function CustomRoute() {
  let location = useLocation();
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <Routes>
      {/* S:: 온강 메인 페이지 */}
      <Route path="/" element={<Pages.MainPage />} />
      {/* E:: 온강 메인 페이지 */}

      {/* S:: 온강 마약센터 소개 페이지 */}
      <Route path="/intro/:contents" element={<Pages.IntroPage />} />
      <Route path="/intro/expert/detail/:id" element={<Pages.IntroExertDetailPage />} />
      <Route path="/intro/news/detail/:id" element={<Pages.IntroNewsDetailPage />} />
      {/* E:: 온강 마약센터 소개 페이지 */}

      {/* S:: 성공사례 */}
      <Route path="/example" element={<Pages.ExamplePage />} />
      <Route path="/example/detail/:id" element={<Pages.ExampleDetailPage />} />
      {/* E:: 성공사례 */}

      {/* E:: 상황별 대응전략 페이지 */}
      <Route path="/situation/:contents" element={<Pages.SituationPage />} />
      {/* E:: 상황별 대응전략 페이지 */}

      {/* E:: 프리미엄 시스템 */}
      <Route path="/system" element={<Pages.SystemPage />} />
      {/* E:: 프리미엄 시스템 */}

      {/* S:: 온강 이야기 페이지 */}
      <Route path="/story/:contents" element={<Pages.StoryPage />} />
      <Route path="/story/review/detail/:id" element={<Pages.StoryReviewDetailPage />} />
      <Route path="/story/column/detail/:id" element={<Pages.StoryColumnDetailPage />} />
      {/* E:: 온강 이야기 페이지 */}

      {/* S:: 상담신청 */}
      <Route path="/counsel" element={<Pages.CounselPage />} />
      {/* E:: 상담신청 */}
    </Routes>
  );
}
