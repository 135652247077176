import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Styles from './styles';

// img
import IconLogoWh from "resources/image/icon/icon_logo_wh.svg";
import IconLogo from "resources/image/icon/icon_logo.svg";
import IconCounsel from "resources/image/icon/icon_counsel.svg";

function Header() {
  const ref = useRef();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [on, setOn] = useState(false);

  useEffect(() => {
    document.querySelector('#header').addEventListener("mouseenter", handle);
    document.querySelector('#header').addEventListener("mouseleave", handleOut);
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY > 0)
  };
  const handle = (e) => {
    setOn(true);
  }
  const handleOut = (e) => {
    setOn(false);
  }

  return (
    <Styles.Header id="header" ref={ref} className={[scroll || on ? "on" : "", on ? "open" : '']}>
      <header className="header">
        <h1
          className="logo"
          onClick={() => {navigate("/");}}
        >
          <img src={scroll || on ? IconLogo : IconLogoWh} alt="logo" />
        </h1>
        <ul className="gnb">
          <li>
            <span onClick={() => {navigate("/intro/introduce");}}>온강 마약센터</span>
            {
              on &&
              <ul className="lnb">
                <li onClick={() => {navigate("/intro/introduce");}}><span>온강 소개</span></li>
                <li onClick={() => {navigate("/intro/special");}}><span>온강의 특별함</span></li>
                <li onClick={() => {navigate("/intro/expert");}}><span>전문가 소개</span></li>
                <li onClick={() => {navigate("/intro/news");}}><span>언론보도</span></li>
                <li onClick={() => {navigate("/intro/map");}}><span>오시는 길</span></li>
              </ul>
            }
          </li>
          <li>
            <span onClick={() => {navigate("/example");}}>성공사례</span>
            {
              on &&
              <ul className="lnb">
                <li onClick={() => {navigate("/example");}}><span>성공사례</span></li>
              </ul>
            }
          </li>
          <li>
            <span onClick={() => {navigate("/situation/medication");}}>상황별 대응전략</span>
            {
              on &&
              <ul className="lnb">
                <li onClick={() => {navigate("/situation/medication");}}><span>구매 투약</span></li>
                <li onClick={() => {navigate("/situation/sales");}}><span>판매유통</span></li>
                <li onClick={() => {navigate("/situation/smuggling");}}><span>밀수제조</span></li>
                <li onClick={() => {navigate("/situation/arrest");}}><span>체포구속상태</span></li>
              </ul>
            }
          </li>
          <li>
            <span onClick={() => {navigate("/system");}}>프리미엄 시스템</span>
            {
              on &&
              <ul className="lnb">
                <li onClick={() => {navigate("/system");}}><span>온강의<br/>프리미엄 시스템</span></li>
              </ul>
            }
          </li>
          <li>
            <span onClick={() => {navigate("/story/review");}}>온강 이야기</span>
            {
              on &&
              <ul className="lnb">
                <li onClick={() => {navigate("/story/review");}}><span>의뢰인 후기</span></li>
                <li onClick={() => {navigate("/story/youtube");}}><span>유튜브</span></li>
                <li onClick={() => {navigate("/story/column");}}><span>컬럼</span></li>
              </ul>
            }
          </li>
        </ul>
        <button className="btn_counsel" onClick={() => {navigate("/counsel");}}>
          <img src={IconCounsel} alt="logo" />
          <span>상담신청</span>
        </button>
      </header>
    </Styles.Header>
  );
}

export { Header };

export default Header;