import React, { useState } from "react";
import styled from "styled-components";
import ImgBox from "../ImgBox";

import { PaginationUI } from "../PaginationUI";
import { NavLink } from "react-router-dom";
import { IMG } from "constant";
import Pagination from "../Pagination";
import { IMAGEPATH } from "service/connector";

export const GridList = ({ list, pagination }) => {
  const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxCount, setMaxCount] = useState(0);
  const pageSize = 5;
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: pageSize,
    recordSize: 6,
    type: "0",
    start_date: "",
    end_date: "",
  });
  return (
    <GridListStyle className={`grid-list-container`}>
      <ul className="grid-list">
        {list.map((listItem, listIdx) => {
          return (
            <li className="grid-item" key={listIdx}>
              <NavLink className={"link"} to={"/story/review/detail/" + listItem.id}>
                <ImgBox src={IMAGEPATH + listItem.image || IMG.ExImg} />
                <div className="text-wrap">
                  <p className="title">{listItem.title}</p>
                  <p className="sub-title">{listItem.description_text}</p>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
      {pagination?.page_list !== 0 && 
        <Pagination 
          page={pagination?.page}
          list={pagination?.page_list}
          maxPage={pagination?.totalPageCount}
        />
      }
    </GridListStyle>
  );
};

const GridListStyle = styled.div`
  &.grid-list-container {
    padding-top: 80px;

    & .grid-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 70px 27px;

      & .grid-item {
        .title {
          min-height: 54px;
          font-size: 18px;
          font-weight: 700;
          line-height: 150%;
          margin-bottom: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .sub-title {
          font-size: 16px;
          line-height: 140%;
          color: #888;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        & .img-box {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 1520px) {
    &.grid-list-container {
      padding-top: calc(100vw * (80 / 1520));

      & .grid-list {
        gap: calc(100vw * (70 / 1520)) calc(100vw * (27 / 1520));

        & .grid-item {
          .title {
            min-height: calc(100vw * (54 / 1520));
            font-size: calc(100vw * (18 / 1520));
            margin-bottom: calc(100vw * (14 / 1520));
          }
          .sub-title {
            font-size: calc(100vw * (16 / 1520));
          }
          & .img-box {
            margin-bottom: calc(100vw * (20 / 1520));
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    &.grid-list-container {
      padding-top: calc(100vw * (40 / 375));

      & .grid-list {
        grid-template-columns: 1fr;
        gap: 0;

        & .grid-item {
          padding-bottom: calc(100vw * (30 / 375));
          margin-bottom: calc(100vw * (30 / 375));
          border-bottom: 1px solid #c4c4c4;
          .title {
            min-height: calc(100vw * (54 / 375));
            font-size: calc(100vw * (20 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }
          .sub-title {
            font-size: calc(100vw * (14 / 375));
          }
          & .img-box {
            margin-bottom: calc(100vw * (20 / 375));
          }
        }
      }
    }
  }
`;
