import styled, { css } from 'styled-components';

// img
import ProcessBg from "resources/image/main/process_bg.jpg";
import MbProcessBg from "resources/image/main/mb_process_bg.jpg";

const MainProcess = styled.div`
  position: relative;
  width: 100%; height: 860px;
  padding: 148px 0 130px;
  background: #000 url(${ProcessBg}) no-repeat center /1920px;
  overflow: hidden;
  & .process_inner {
    display: flex;
    align-items: center;
    width: 1260px;
    margin: 0 auto;
    color: #fff;
    & h2 {
      font-size: 48px;
      line-height: 57px;
      font-weight: 900;
      padding-left: 82px;
    }
    & .tab_btn {
      position: relative;
      width: 505px;
      margin-top: 50px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 100%; height: 100px;
        border-radius: 100px;
        background-color: #DA250B;
        display: block;
        transition: 0.3s;
      }
      & li {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%; height: 100px;
        padding: 31px 31px 31px 61px;
        border-radius: 100px;
        font-size: 32px;
        font-weight: bold;
        color: #C4C4C4;
        cursor: pointer;
        z-index: 1;
        &.active {
          color: #fff;
        }
        & span {width: 68px;}
        & p {
          display: flex;
          align-items: center;
        }
        & img {width: 32px; height: 32px;}
      }
    }
    & .tab_contents {
      position: relative;
      display: flex;
      width: calc(100% - 505px);
      padding-left: 155px;
      overflow: hidden;
      & .tab_box {
        position: absolute;
        left: 100%;
        top: 0;
        opacity: 0;
        transition: 0.8s;
        &.active {
          position: relative;
          left: 0;
          opacity: 1;
        }
      }
      & img {width: 600px; height: 404px; object-fit: cover; border-radius: 5px;}
      & p {
        font-size: 30px;
        line-height: 42px;
        margin-top: 25px;
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) { 
    height: calc(100vw*(860/1520));
    padding: calc(100vw*(148/1520)) 0 calc(100vw*(130/1520));
    background-size: calc(100vw*(1920/1520));
    & .process_inner {
      width: calc(100vw*(1260/1520));
      & h2 {
        font-size: calc(100vw*(48/1520));
        line-height: calc(100vw*(57/1520));
        padding-left: calc(100vw*(82/1520));
      }
      & .tab_btn {
        width: calc(100vw*(505/1520));
        margin-top: calc(100vw*(50/1520));
        &::before {
          height: calc(100vw*(100/1520));
          border-radius: calc(100vw*(100/1520));
        }
        & li {
          height: calc(100vw*(100/1520));
          padding: calc(100vw*(31/1520)) calc(100vw*(31/1520)) calc(100vw*(31/1520)) calc(100vw*(61/1520));
          border-radius: calc(100vw*(100/1520));
          font-size: calc(100vw*(32/1520));
          & span {width: calc(100vw*(68/1520));}
          & img {width: calc(100vw*(32/1520)); height: calc(100vw*(32/1520));}
        }
      }
      & .tab_contents {
        width: calc(100% - calc(100vw*(505/1520)));
        padding-left: calc(100vw*(155/1520));
        & img {width: calc(100vw*(600/1520)); height: calc(100vw*(404/1520)); object-fit: cover; border-radius: calc(100vw*(5/1520));}
        & p {
          font-size: calc(100vw*(30/1520));
          line-height: calc(100vw*(42/1520));
          margin-top: calc(100vw*(25/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) { 
    height: calc(100vw*(854/375));
    padding: calc(100vw*(50/375)) calc(100vw*(20/375));
    background-image: url(${MbProcessBg});
    background-size: 100%;
    & .process_inner {
      flex-direction: column;
      width: 100%;
      & .tab_menu {width: 100%; margin-bottom: calc(100vw*(30/375));}
      & h2 {
        display: flex;
        justify-content: center;
        font-size: calc(100vw*(32/375));
        line-height: calc(100vw*(38/375));
        padding-left: 0;
      }
      & .tab_btn {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin-top: calc(100vw*(30/375));
        &::before {
          height: calc(100vw*(68/375));
          border-radius: calc(100vw*(68/375));
        }
        & li {
          height: calc(100vw*(68/375));
          padding: 0 calc(100vw*(31/375));
          border-radius: calc(100vw*(100/375));
          font-size: calc(100vw*(20/375));
          & span {width: calc(100vw*(42/375));}
          & img {width: calc(100vw*(32/375)); height: calc(100vw*(32/375));}
        }
      }
      & .tab_contents {
        width: 100%;
        padding-left: 0;
        & img {width: 100%; height: calc(100vw*(226/375)); object-fit: cover; border-radius: calc(100vw*(3/375));}
        & p {
          font-size: calc(100vw*(18/375));
          line-height: calc(100vw*(25/375));
          margin-top: calc(100vw*(15/375));
        }
      }
    }
  }
`;

const TabBtn = styled.div`
  &::before {
    top: ${props => props.top}px;
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    &::before {
      top: calc(100vw*(${props => props.top}/1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    &::before {
      top: calc(100vw*(${props => props.top}/375));
    }
  }
`;




export {
  MainProcess,
  TabBtn
}

export default {
  MainProcess,
  TabBtn
}