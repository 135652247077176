import styled from 'styled-components';

const SubExample = styled.div`
  width: 100%;
  padding: 80px 0 30px;
  border-bottom: 1px solid #E4E4E4;
  & .sub_example_inner {
    position: relative;
    width: 1500px;
    margin: 0 auto;
    & h2 {
      width: 1260px;
      font-size: 36px;
      line-height: 43px;
      font-weight: bold;
      letter-spacing: -0.5px;
      text-align: left;
      padding-bottom: 20px;
      border-bottom: 3px solid #DA250B;
      margin: 0 auto;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw*(80/1520)) 0 calc(100vw*(30/1520));
    & .sub_example_inner {
      width: calc(100vw*(1500/1520));
      & h2 {
        width: calc(100vw*(1260/1520));
        font-size: calc(100vw*(36/1520));
        line-height: calc(100vw*(43/1520));
        padding-bottom: calc(100vw*(20/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw*(50/375)) 0 calc(100vw*(20/375));
    & .sub_example_inner {
      width: 100%;
      & h2 {
        width: calc(100vw*(335/375));
        font-size: calc(100vw*(26/375));
        line-height: calc(100vw*(31/375));
        padding-bottom: calc(100vw*(15/375));
      }
    }
  }
`;




export {
  SubExample
}

export default {
  SubExample
}