import connector from "service/connector";
import utils from "utils";

const doInquiry = async (data) => {
  return await connector.jsonConnector(
    `inquiry`,
    "POST",
    data
  );
};

export { doInquiry };

export default {
  doInquiry,
};
