import { api } from "service";
import { useInfiniteQuery, useQuery } from "react-query";

export default function useSite({ listOption }) {
  const listQuery = useQuery(
    ["board_list", listOption],
    () => {
      return listOption && api.site.getList(listOption);
    },
    {
      enabled: !!listOption,
      refetchOnWindowFocus: false,
    }
  );

  return {
    listQuery,
  };
}
