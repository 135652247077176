import styled, { css } from 'styled-components';

// img
import DirectionBg from "resources/image/main/direction_bg.jpg";
import MbDirectionBg from "resources/image/main/mb_direction_bg.jpg";

const MainDirection = styled.div`
  position: relative;
  width: 100%; height: 750px;
  padding: 92px 0;
  background: #000 url(${DirectionBg}) no-repeat top center /1920px;
  overflow: hidden;
  & .map_inner {
    width: 1260px;
    margin: 0 auto;
    & .tab {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      margin-bottom: 20px;
      & li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        padding: 12px 30px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50px;
        cursor: pointer;
        &.active {
          background-color: #DA250B;
          border-color: #DA250B;
        }
      }
    }
    & .map_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 510px;
      & .map {
        width: calc(100% - 569px); height: 100%;
      }
      & .map_info {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 545px; height: 100%;
        background-color: #111;
        padding: 64px 55px;
        & .map_box {
          display: flex;
          & .map_icon {
            width: 40px; height: 40px;
          }
          & .map_text {
            color: #fff;
            padding-left: 20px;
            & .tit {
              font-size: 20px;
              line-height: 24px;
              font-weight: 800;
            }
            & .dec {
              font-size: 18px;
              line-height: 21px;
              margin-top: 10px;
              letter-spacing: -0.5px;
            }
          }
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(750/1520));
    padding: calc(100vw*(92/1520)) 0;
    background-size: calc(100vw*(1920/1520));
    & .map_inner {
      width: calc(100vw*(1260/1520));
      & .tab {
        gap: calc(100vw*(10/1520));
        margin-bottom: calc(100vw*(20/1520));
        & li {
          height: calc(100vw*(45/1520));
          padding: calc(100vw*(12/1520)) calc(100vw*(30/1520));
          font-size: calc(100vw*(18/1520));
          border-radius: calc(100vw*(50/1520));
        }
      }
      & .map_content {
        height: calc(100vw*(510/1520));
        & .map {
          width: calc(100% - calc(100vw*(569/1520)));
        }
        & .map_info {
          gap: calc(100vw*(40/1520));
          width: calc(100vw*(545/1520));
          padding: calc(100vw*(64/1520)) calc(100vw*(55/1520));
          & .map_box {
            & .map_icon {
              width: calc(100vw*(40/1520)); height: calc(100vw*(40/1520));
            }
            & .map_text {
              padding-left: calc(100vw*(20/1520));
              & .tit {
                font-size: calc(100vw*(20/1520));
                line-height: calc(100vw*(24/1520));
              }
              & .dec {
                font-size: calc(100vw*(18/1520));
                line-height: calc(100vw*(21/1520));
                margin-top: calc(100vw*(10/1520));
              }
            }
          }
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    height: calc(100vw*(846/375));
    padding: calc(100vw*(50/375)) calc(100vw*(20/375));
    background-image: url(${MbDirectionBg});
    background-size: 100%;
    & .map_inner {
      width: 100%;
      & .tab {
        justify-content: flex-start;
        gap: calc(100vw*(5/375));
        margin-bottom: calc(100vw*(30/375));
        & li {
          height: calc(100vw*(43/375));
          padding: calc(100vw*(12/375)) calc(100vw*(17/375));
          font-size: calc(100vw*(16/375));
          border-radius: calc(100vw*(45/375));
          letter-spacing: -0.5px;
          word-wrap: nowrap;
        }
      }
      & .map_content {
        flex-direction: column;
        height: auto;
        & .map {
          width: 100%; height: calc(100vw*(300/375));
          object-fit: cover;
        }
        & .map_info {
          gap: calc(100vw*(26/375));
          width: 100%;
          padding: calc(100vw*(38/375)) calc(100vw*(8/375));
          & .map_box {
            & .map_icon {
              width: calc(100vw*(30/375)); height: calc(100vw*(30/375));
            }
            & .map_text {
              padding-left: calc(100vw*(10/375));
              & .tit {
                font-size: calc(100vw*(16/375));
                line-height: calc(100vw*(19/375));
              }
              & .dec {
                font-size: calc(100vw*(14/375));
                line-height: calc(100vw*(17/375));
                margin-top: calc(100vw*(10/375));
                word-break: keep-all;
              }
            }
          }
        }
      }
    }
  }
`;




export {
  MainDirection
}

export default {
  MainDirection
}