import React from "react";
import styled from "styled-components";

const ArticleHeading = ({ children, title }) => {
  return (
    <HeadingWrap>
      <div className="head">
        <p className="title">{title}</p>
        {children}
      </div>
    </HeadingWrap>
  );
};

export default ArticleHeading;

const HeadingWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .head {
    width: 100%;
    max-width: 1260px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    gap: 30px;
    border-bottom: 3px solid #da250b;
    padding-bottom: 20px;

    .title {
      font-size: 36px;
      font-weight: 800;
      line-height: 43px;
    }
  }

  & .btn-more {
    color: Red;
  }

  @media (max-width: 1520px) {
    & .head {
      margin: 0 calc(100vw * (10 / 1520));
      gap: calc(100vw * (30 / 1520));
      padding-bottom: calc(100vw * (20 / 1520));
      .title {
        font-size: calc(100vw * (36 / 1520));
        line-height: calc(100vw * (43 / 1520));
      }
    }

    & .btn-more {
      color: Red;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 calc(100vw * (20 / 375));
    & .head {
      margin: 0;
      gap: calc(100vw * (30 / 375));
      padding-bottom: calc(100vw * (15 / 375));
      .title {
        font-size: calc(100vw * (26 / 375));
        line-height: calc(100vw * (33 / 375));
      }
    }

    & .btn-more {
      color: Red;
    }
  }
`;
