import styled, { css } from "styled-components";

// img
import BroadcastImg from "resources/image/main/broadcast_bg.png";
import MbBroadcastImg from "resources/image/main/mb_broadcast_bg.jpg";
import IconNext from "resources/image/slide/icon_next_wh.svg";
import iconPrev from "resources/image/slide/icon_prev_wh.svg";
import blurBg from "resources/image/main/blur_bg.png";

const MainLawyer = styled.div`
  position: relative;
  & .broad_banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 860px;
    background: url(${BroadcastImg}) no-repeat center / 1920px;
    & p {
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      & span {
        color: #da250b;
      }
    }
    & h2 {
      font-size: 72px;
      line-height: 101px;
      font-weight: 400;
      letter-spacing: -0.5px;
      text-align: center;
      color: #fff;
      margin-top: 10px;
      opacity: 0;
      & strong {
        font-weight: 800;
      }
      & br {
        display: none;
      }
    }
    &.active {
      & h2 {
        animation: fadeUp 0.8s ease-in 0.2s forwards;
      }
    }
  }
  & .swiper {
    width: 100%;
    background-color: #000;
    & .swiper-slide {
      transition: 0.8s;
      pointer-events: none;
      &.swiper-slide-active .lawyer_inner .btn_more {
        pointer-events: auto;
      }
    }
    & .swiper-button-prev {
      width: 70px;
      height: 70px;
      background: url(${iconPrev}) no-repeat center / contain;
      left: 50%;
      margin-left: -750px;
      &::after {
        display: none;
      }
    }
    & .swiper-button-next {
      width: 70px;
      height: 70px;
      background: url(${IconNext}) no-repeat center / contain;
      right: 50%;
      margin-right: -750px;
      &::after {
        display: none;
      }
    }
  }
  & .lawyer_slide {
    position: relative;
    width: 100%;
    height: 860px;
    color: #fff;
    & .lawyer_inner {
      position: relative;
      width: 1260px;
      height: 770px;
      padding: 100px 0 90px;
      margin: 0 auto;
      z-index: 1;
      & .lawyer_tit {
        font-size: 36px;
        font-weight: 400;
        color: #da250b;
        & strong {
          font-size: 62px;
          font-weight: bold;
          letter-spacing: 12px;
          color: #fff;
        }
      }
      & .btn_more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 190px;
        height: 45px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        padding: 0 30px;
        margin-top: 20px;
        border-radius: 30px;
        border: 1px solid #fff;
        transition: 0.5s;
        & img {
          width: 18px;
          height: 18px;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          & img {
            animation: rotate 0.8s ease-in-out forwards;
          }
        }
      }
      & .lawyer_list {
        display: flex;
        gap: 60px;
        margin-top: 40px;
        & ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 335px;
          & li {
            position: relative;
            font-size: 16px;
            line-height: 19px;
            padding-left: 11px;
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              width: 5px;
              height: 5px;
              background-color: #fff;
              border-radius: 50%;
              transform: translateY(-50%);
            }
            & b {
              font-weight: 700;
            }
          }
        }
      }
      & .tag_box {
        display: flex;
        align-items: center;
        gap: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        & .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 290px;
          height: 81px;
          font-size: 20px;
          text-align: center;
          border-radius: 90px;
          background-color: rgba(255, 255, 255, 0.1);
          b {
            font-weight: 800;
            margin-right: 5px;
          }
        }
      }
    }
    & .bnnr_img {
      position: absolute;
      top: 0;
      right: 0;
      width: 1022px;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .broad_banner {
      height: calc(100vw * (860 / 1520));
      & p {
        font-size: calc(100vw * (36 / 1520));
        line-height: calc(100vw * (50 / 1520));
      }
      & h2 {
        font-size: calc(100vw * (72 / 1520));
        line-height: calc(100vw * (101 / 1520));
        margin-top: calc(100vw * (10 / 1520));
      }
    }
    & .swiper {
      & .swiper-button-prev {
        width: calc(100vw * (70 / 1520));
        height: calc(100vw * (70 / 1520));
        margin-left: calc(100vw * (-750 / 1520));
      }
      & .swiper-button-next {
        width: calc(100vw * (70 / 1520));
        height: calc(100vw * (70 / 1520));
        margin-right: calc(100vw * (-750 / 1520));
      }
    }
    & .lawyer_slide {
      height: calc(100vw * (860 / 1520));
      & .lawyer_inner {
        width: calc(100vw * (1260 / 1520));
        height: calc(100vw * (770 / 1520));
        padding: calc(100vw * (100 / 1520)) 0 calc(100vw * (90 / 1520));
        & .lawyer_tit {
          font-size: calc(100vw * (36 / 1520));
          & strong {
            font-size: calc(100vw * (62 / 1520));
            letter-spacing: calc(100vw * (12 / 1520));
          }
        }
        & .btn_more {
          width: calc(100vw * (190 / 1520));
          height: calc(100vw * (45 / 1520));
          font-size: calc(100vw * (18 / 1520));
          padding: 0 calc(100vw * (30 / 1520));
          margin-top: calc(100vw * (20 / 1520));
          border-radius: calc(100vw * (30 / 1520));
          & img {
            width: calc(100vw * (18 / 1520));
            height: calc(100vw * (18 / 1520));
          }
        }
        & .lawyer_list {
          gap: calc(100vw * (60 / 1520));
          margin-top: calc(100vw * (40 / 1520));
          & ul {
            gap: calc(100vw * (12 / 1520));
            width: calc(100vw * (335 / 1520));
            & li {
              font-size: calc(100vw * (16 / 1520));
              line-height: calc(100vw * (19 / 1520));
              padding-left: calc(100vw * (11 / 1520));
              &::after {
                width: calc(100vw * (5 / 1520));
                height: calc(100vw * (5 / 1520));
              }
            }
          }
        }
        & .tag_box {
          gap: calc(100vw * (30 / 1520));
          & .tag {
            width: calc(100vw * (290 / 1520));
            height: calc(100vw * (81 / 1520));
            font-size: calc(100vw * (20 / 1520));
            border-radius: calc(100vw * (90 / 1520));
            b {
              margin-right: calc(100vw * (5 / 1520));
            }
          }
        }
      }
      & .bnnr_img {
        width: calc(100vw * (1022 / 1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .broad_banner {
      height: calc(100vw * (609 / 375));
      background-image: url(${MbBroadcastImg});
      background-size: 100%;
      & p {
        font-size: calc(100vw * (18 / 375));
        line-height: calc(100vw * (25 / 375));
      }
      & h2 {
        font-size: calc(100vw * (36 / 375));
        line-height: calc(100vw * (50 / 375));
        margin-top: calc(100vw * (20 / 375));
        & br {
          display: block;
        }
      }
    }
    & .swiper {
      pointer-events: auto;
      & .swiper-slide {
        pointer-events: auto;
      }
      & .swiper-button-prev {
        width: calc(100vw * (30 / 375));
        height: calc(100vw * (30 / 375));
        top: calc(100vw * (143 / 375));
        left: calc(100vw * (20 / 375));
        transform: none;
        margin: 0;
      }
      & .swiper-button-next {
        width: calc(100vw * (30 / 375));
        height: calc(100vw * (30 / 375));
        top: calc(100vw * (143 / 375));
        right: calc(100vw * (20 / 375));
        transform: none;
        margin: 0;
      }
    }
    & .lawyer_slide {
      display: flex;
      flex-direction: column-reverse;
      height: calc(100vw * (853 / 375));
      background: url(${blurBg}) no-repeat center/cover;
      & .lawyer_inner {
        width: 100%;
        height: calc(100vw * (537 / 375));
        padding: calc(100vw * (50 / 375)) calc(100vw * (20 / 375));
        & .lawyer_tit {
          font-size: calc(100vw * (18 / 375));
          & strong {
            font-size: calc(100vw * (36 / 375));
            line-height: calc(100vw * (62 / 375));
            letter-spacing: calc(100vw * (12 / 375));
          }
        }
        & .btn_more {
          width: calc(100vw * (150 / 375));
          height: calc(100vw * (39 / 375));
          font-size: calc(100vw * (16 / 375));
          padding: 0 calc(100vw * (20 / 375));
          margin-top: calc(100vw * (10 / 375));
          border-radius: calc(100vw * (39 / 375));
          & img {
            width: calc(100vw * (18 / 375));
            height: calc(100vw * (18 / 375));
          }
        }
        & .lawyer_list {
          height: calc(100vw * (170 / 375));
          overflow-y: auto;
          flex-direction: column;
          gap: calc(100vw * (8 / 375));
          margin-top: calc(100vw * (20 / 375));
          & ul {
            gap: calc(100vw * (8 / 375));
            width: 100%;
            & li {
              font-size: calc(100vw * (14 / 375));
              line-height: calc(100vw * (17 / 375));
              padding-left: calc(100vw * (11 / 375));
              &::after {
                width: calc(100vw * (4 / 375));
                height: calc(100vw * (4 / 375));
              }
            }
          }
          &::-webkit-scrollbar {
            display: block;
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            width: 5px;
            height: 5px;
            background: #da250b;
            border-radius: 999px;
          }
        }
        & .tag_box {
          position: initial;
          align-items: flex-start;
          flex-direction: column;
          gap: calc(100vw * (10 / 375));
          margin-top: calc(100vw * (20 / 375));
          & .tag {
            width: calc(100vw * (220 / 375));
            height: calc(100vw * (50 / 375));
            font-size: calc(100vw * (16 / 375));
            border-radius: calc(100vw * (50 / 375));
            b {
              margin-right: calc(100vw * (4 / 375));
            }
          }
        }
      }
      & .bnnr_img {
        position: initial;
        width: 100%;
        height: calc(100vw * (316 / 375));
      }
    }
  }
`;

export { MainLawyer };

export default {
  MainLawyer,
};
