import styled, { css } from 'styled-components';

// img
import bannerBg from "resources/image/main/main_banner_bg.png";

const MainBanner = styled.div`
  position: relative;
  width: 100%; height: 980px;
  background: #000 url(${bannerBg}) no-repeat center /1920px;
  overflow: hidden;
  &.active {
    & .bnnr_tit p {
      animation: fadeUp 0.8s ease-in forwards;
    }
    & .bnnr_tit h2 {
      animation: fadeUp 0.8s ease-in forwards;
    }
  }
  & .bnnr_inner {
    padding-top: 140px;
    & .bnnr_tit {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      width: 990px; height: 936px;
      color: #fff;
      padding-top: 90px;
      margin: 0 auto;
      & p {
        font-size: 36px;
        font-weight: 200;
        text-align: center;
        opacity: 0;
        transition: 0.8s;
        & span {
          font-weight: 500;
          & b {
            color: #DA250B;
            font-weight: 500;
          }
        }
      }
      & h2 {
        font-size: 62px;
        line-height: 74px;
        font-weight: 200;
        text-align: center;
        margin-top: 10px;
        opacity: 0;
        transition: 0.8s;
      }
      & .btn_more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 234px; height: 45px;
        font-size: 18px;
        font-weight: bold;
        padding: 0 30px;
        margin-top: 30px;
        color: #fff;
        border-radius: 30px;
        border: 1px solid #fff;
        transition: 0.5s;
        z-index: 1;
        opacity: 0;
        animation: fadeUp 0.8s ease-in 0.2s forwards;
        & img {
          width: 18px; height: 18px;
        }
        &:hover {
          background-color: rgba(255,255,255,0.1);
          & img {
            animation: rotate 0.8s ease-in-out forwards;
          }
        }
      }
      & .line {
        & i {
          position: absolute;
        }
        & i.top {
          top: 0; left: 0;
          display: block;
          width: 0; height: 2px;
          background-color: #fff;
          animation: row 1.2s ease-in forwards;
        }
        & i.right {
          top: 0; right: 0;
          display: block;
          width: 2px; height: 0;
          background-color: #fff;
          animation: column 1.2s ease-in 0.5s forwards;
        }
        & i.left {
          bottom: 0; left: 0;
          display: block;
          width: 2px; height: 0;
          background-color: #fff;
          animation: column 1.2s ease-in forwards;
        }
      }
    }
  }
  & .bnnr_img {
    position: absolute; bottom: 0; left: 50%;
    display: block;
    width: 1920px;
    transform: translateX(-50%);
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes row {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes column {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(980/1520));
    & .bnnr_inner {
      padding-top: calc(100vw*(140/1520));
      & .bnnr_tit {
        width: calc(100vw*(990/1520)); height: calc(100vw*(936/1520));
        padding-top: calc(100vw*(90/1520));
        & p {
          font-size: calc(100vw*(36/1520));
        }
        & h2 {
          font-size: calc(100vw*(62/1520));
          line-height: calc(100vw*(74/1520));
          margin-top: calc(100vw*(10/1520));
        }
        & .btn_more {
          width: calc(100vw*(234/1520)); height: calc(100vw*(45/1520));
          font-size: calc(100vw*(18/1520));
          padding: 0 calc(100vw*(30/1520));
          margin-top: calc(100vw*(30/1520));
          border-radius: calc(100vw*(30/1520));
          & img {
            width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));
          }
        }
      }
    }
    & .bnnr_img {
      position: absolute; bottom: 0; left: 50%;
      display: block;
      width: calc(100vw*(1920/1520));
      transform: translateX(-50%);
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    height: calc(100vw*(620/375));
    & .bnnr_inner {
      padding-top: calc(100vw*(102/375));
      & .bnnr_tit {
        width: calc(100vw*(310/375)); height: calc(100vw*(518/375));
        line-height: calc(100vw*(27/375));
        padding-top: calc(100vw*(80/375));
        & p {
          font-size: calc(100vw*(18/375));
          & span {display: block;}
        }
        & h2 {
          font-size: calc(100vw*(32/375));
          line-height: calc(100vw*(38/375));
          margin-top: calc(100vw*(10/375));
        }
        & .btn_more {
          width: calc(100vw*(190/375)); height: calc(100vw*(39/375));
          font-size: calc(100vw*(16/375));
          padding: 0 calc(100vw*(20/375));
          margin-top: calc(100vw*(30/375));
          border-radius: calc(100vw*(40/375));
          & img {
            width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
          }
        }
      }
    }
    & .bnnr_img {
      position: absolute; bottom: 0; left: 50%;
      display: block;
      width: 100%;
      transform: translateX(-50%);
    }
  }
`;




export {
  MainBanner
}

export default {
  MainBanner
}