import { IMG } from "constant";
import { useDeviceType } from "hooks/useMediaQuery";
import React from "react";

const Intro = () => {
  const deviceType = useDeviceType();

  return (
    <section className="section description full">
      <div className="intro-wrap">
        <div className="intro-top">
          <img className="member-img" src={IMG.MEMBER} alt="members" />
        </div>
        <div className="intro-bottom">
          <img className="brand-logo" src={IMG.BRAND_LOGO} alt="brand logo" />
          <div className="summary">
            <div className="text-wrap">
              <h3>
                우리 의뢰인에게는 <strong>한없이 따뜻하게</strong>
                <br /> 상대편인 수사 기관에게는 <strong>누구보다 강인하게</strong>
              </h3>
              <h4>
                온강의 이름에는 의뢰인을 향한 온강만의 신념과 철학이 담겨있습니다.
                <br />
                법무법인 온강 마약센터를 믿고 맡겨주신 의뢰인에게
                <br />
                다시 일상으로 돌아갈 수 있도록,
                <br />
                다시는 같은 실수를 반복하시지 않도록
                <br />
                저희가 가진 전문성과 진정성으로 열과 성을 다해 끝까지 변호하겠습니다.
              </h4>
            </div>
            <img className="typo-img" src={deviceType === "pc" ? IMG.TYPO : IMG.MB_TYPO} alt="typo img 의뢰인의 믿음에 결과로 보답하는 로펌, 법무법인 온강 입니다" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
