import Footer from "layout/Footer";
import Header from "layout/Header";
import MbHeader from "layout/MbHeader";
import React from "react";
import { Outlet } from "react-router-dom";
import { PC, Mobile } from "hooks/useMediaQuery";

export default function Container() {
  return (
    <div className="container">
      <div className="container-inner">
        <PC><Header /></PC>
        <Mobile><MbHeader /></Mobile>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
