import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 47px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(#000 0 0) padding-box, var(--gradient) border-box;
  border: 2px solid transparent;
  border-radius: 16px;
  overflow: hidden;

  & .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    padding: 24px 32px;
    background: linear-gradient(
      90deg,
      rgba(71, 118, 230, 0.7) 0%,
      rgba(142, 84, 233, 0.7) 100%
    );
  }

  @media (max-width: 1520px) {
    bottom: calc(100vw * (47 / 1520));
    border-radius: calc(100vw * (16 / 1520));

    & .inner {
      min-height: calc(100vw * (70 / 1520));
      padding: calc(100vw * (24 / 1520)) calc(100vw * (32 / 1520));
    }
  }

  @media (max-width: 820px) {
    width: calc(100vw - calc(100vw * (40 / 360)));
    bottom: calc(100vw * (81 / 360));
    border-radius: calc(100vw * (16 / 360));

    & .inner {
      min-height: calc(100vw * (50 / 360));
      padding: calc(100vw * (16 / 360));
      font-size: calc(100vw * (12 / 360));
    }
  }
`;

export { Container };

export default { Container };
