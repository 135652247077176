import React from "react";
import { useMediaQuery } from "react-responsive";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return <React.Fragment>{isMobile && children}</React.Fragment>;
};

const PC = ({ children }) => {
  const isPc = useMediaQuery({ minWidth: 1025 });
  return <React.Fragment>{isPc && children}</React.Fragment>;
};

const useDeviceType = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isPc = useMediaQuery({ minWidth: 1025 });
  
  if (isMobile) return "mobile";
  if (isPc) return "pc";
  return "err";
};

export { Mobile, PC, useDeviceType };
