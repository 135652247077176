import styled from "styled-components";

const InputSearch = styled.div`
  position: relative;
  width: 100%;
  & input {
    width: 100%;
    height: 50px;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    color: #111;
    background-color: #F5F5F5;
    border-radius: 50px;

    &::placeholder {
      color: #888;
    }
  }
  & button {
    position: absolute;
    top: 50%; right: 30px;
    width: 24px; height: 24px;
    transform: translateY(-50%);
    & img {width: 100%; height: 100%;}
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & input {
      height: calc(100vw*(50/1520));
      padding: 0 calc(100vw*(30/1520));
      font-size: calc(100vw*(16/1520));
      border-radius: calc(100vw*(50/1520));
    }
    & button {
      right: calc(100vw*(30/1520));
      width: calc(100vw*(24/1520)); height: calc(100vw*(24/1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & input {
      height: calc(100vw*(54/375));
      padding: 0 calc(100vw*(30/375));
      font-size: calc(100vw*(16/375));
      border-radius: calc(100vw*(50/375));
    }
    & button {
      right: calc(100vw*(30/375));
      width: calc(100vw*(24/375)); height: calc(100vw*(24/375));
    }
  }
`;

export { InputSearch };

export default { InputSearch };
