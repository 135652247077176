import styled from "styled-components";

const ImgBoxStyle = styled.div`
  .img-box {
    position: relative;
    margin-bottom: 30px;

    .float-type {
      position: absolute;
      left: 0;
      top: 0;
      background: #da250b;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      padding: 5px 27px;
    }
  }
  @media (max-width: 1520px) {
    .img-box {
      margin-bottom: calc(100vw * (30 / 1520));

      .float-type {
        font-size: calc(100vw * (18 / 1520));
        padding: calc(100vw * (5 / 1520)) calc(100vw * (27 / 1520));
      }
    }
  }

  @media (max-width: 1024px) {
    .img-box {
      margin-bottom: calc(100vw * (15 / 375));

      .float-type {
        font-size: calc(100vw * (14 / 375));
        padding: calc(100vw * (5 / 375)) calc(100vw * (20 / 375));
      }
    }
  }
`;

export { ImgBoxStyle };

export default { ImgBoxStyle };
