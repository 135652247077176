import styled from 'styled-components';

const ExampleDetail = styled.div`
  position: relative;
  width: 1260px;
  padding-top: 80px;
  padding-bottom: 100px;
  margin: 0 auto;
  & .detail_wrap {
    border-top: 2px solid #DA250B;
    & .detail_inner {
      & .title_box {
        position: relative;
        padding-bottom: 50px;
        &::after {
          content: '';
          position: absolute;
          bottom: 0; left: 50%;
          display: block;
          width: 100vw; height: 1px;
          background-color: #E4E4E4;
          transform: translate(-50%);
        }
        & .tag {
          display: inline-block;
          padding: 6px 30px;
          font-size: 18px;
          line-height: 21px;
          font-weight: 600;
          color: #fff;
          background-color: #DA250B;
          margin-bottom: 30px;
        }
        & .detail_tit {
          font-size: 24px;
          line-height: 36px;
          font-weight: bold;
        }
        & .date_box {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: #888;
          margin-top: 20px;
          & .group {
            display: flex;
            align-items: center;
            gap: 10px;
            & b {
              font-weight: bold;
            }
            &::before {
              content: '';
              display: block;
              width: 1px; height: 14px;
              background-color: #E4E4E4;
            }
          }
        }
      }
      & .detail_content {
        width: 100%;
        padding-top: 50px;
        & img {
          max-width: 100%;
        }
      }
    }
    & .back_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 560px; height: 54px;
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      background-color: #DA250B;
      margin: 70px auto 0;
      border-radius: 54px;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw*(1260/1520));
    padding-top: calc(100vw*(80/1520));
    padding-bottom: calc(100vw*(100/1520));
    & .detail_wrap {
      & .detail_inner {
        & .title_box {
          padding-bottom: calc(100vw*(50/1520));
          & .tag {
            padding: calc(100vw*(6/1520)) calc(100vw*(30/1520));
            font-size: calc(100vw*(18/1520));
            line-height: calc(100vw*(21/1520));
            margin-bottom: calc(100vw*(30/1520));
          }
          & .detail_tit {
            font-size: calc(100vw*(24/1520));
            line-height: calc(100vw*(36/1520));
          }
          & .date_box {
            gap: calc(100vw*(10/1520));
            font-size: calc(100vw*(14/1520));
            line-height: calc(100vw*(17/1520));
            margin-top: calc(100vw*(20/1520));
            & .group {
              gap: calc(100vw*(10/1520));
              &::before {
                height: calc(100vw*(14/1520));
              }
            }
          }
        }
        & .detail_content {
          padding-top: calc(100vw*(50/1520));
        }
      }
      & .back_btn {
        width: calc(100vw*(560/1520)); height: calc(100vw*(54/1520));
        font-size: calc(100vw*(18/1520));
        margin: calc(100vw*(70/1520)) auto 0;
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    width: calc(100% - calc(100vw*(40/375)));
    padding-top: calc(100vw*(50/375));
    padding-bottom: calc(100vw*(130/375));
    & .detail_wrap {
      border-width: 3px;
      & .detail_inner {
        & .title_box {
          padding-bottom: calc(100vw*(30/375));
          & .tag {
            padding: calc(100vw*(5/375)) calc(100vw*(20/375));
            font-size: calc(100vw*(14/375));
            line-height: calc(100vw*(17/375));
            margin-bottom: calc(100vw*(20/375));
          }
          & .detail_tit {
            font-size: calc(100vw*(18/375));
            line-height: calc(100vw*(27/375));
          }
          & .date_box {
            gap: calc(100vw*(10/375));
            font-size: calc(100vw*(14/375));
            line-height: calc(100vw*(17/375));
            margin-top: calc(100vw*(15/375));
            & .group {
              gap: calc(100vw*(10/375));
              &::before {
                height: calc(100vw*(14/375));
              }
            }
          }
        }
        & .detail_content {
          padding-top: calc(100vw*(30/375));
        }
      }
      & .back_btn {
        width: 100%; height: calc(100vw*(54/375));
        font-size: calc(100vw*(16/375));
        margin: calc(100vw*(30/375)) auto 0;
      }
    }
  }
`;




export {
  ExampleDetail
}

export default {
  ExampleDetail
}