import { ICON } from "constant";
import React from "react";

const SearchInput = ({ placeholder }) => {
  return (
    <form className="search-input-form">
      <fieldset>
        <legend>검색</legend>
        <div className="search-box">
          <input className="search-input" type="search" name="notice-search" placeholder={placeholder} />
          <button className="search-svg" type="button">
            <img src={ICON.ICON_SEARCH} alt="" />
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default SearchInput;
