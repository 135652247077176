import Container from "layout/Container";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { CustomRoute } from "routes";
import { ModalPage } from "pages";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route path="*" element={<CustomRoute></CustomRoute>} />
        </Route>
      </Routes>
      <ModalPage/>
    </BrowserRouter>
  );
}
