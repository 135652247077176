import { useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles";

function ToastModal({ text, modalOption, modalClose }) {
  useEffect(() => {
    setTimeout(() => {
      modalClose();
    }, 2000);
  }, []);

  return (
    <Styles.Container style={{ zIndex: modalOption.zIndex }}>
      <div className="inner"> {modalOption.text} </div>
    </Styles.Container>
  );
}

export { ToastModal };

export default ToastModal;
