import { api } from "service";
import { useInfiniteQuery, useQuery } from "react-query";

export default function useInquiry() {
  
  const doInquiry = async (categoryId,phone) => {
    const data = {
      "category_info_id": categoryId,
      "phone": phone
    };

    let reuslt = await api.inquiry.doInquiry(JSON.stringify(data));
    return reuslt;
  }

  return {
    doInquiry,
  };
}
