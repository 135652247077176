import { api } from "service";
import { useInfiniteQuery, useQuery } from "react-query";

export default function useBoard({
  listOption,
  groupListOption,
  detailOption,
}) {
  const listQuery = useQuery(
    ["board_list", listOption],
    () => {
      return listOption && api.board.getList(listOption);
    },
    {
      enabled: !!listOption,
      refetchOnWindowFocus: false,
    }
  );

  const groupListQuery = useQuery(
    ["board_group", groupListOption],
    () => {
      return api.board.getGroupList(groupListOption);
    },
    {
      enabled: !!groupListOption,
      refetchOnWindowFocus: false,
    }
  );

  const detailQuery = useQuery(
    ["board_detail", detailOption],
    () => {
      return api.board.getDetail(detailOption?.id);
    },
    {
      enabled: !!detailOption,
      refetchOnWindowFocus: false,
    }
  );

  return {
    listQuery,
    groupListQuery,
    detailQuery,
  };
}
