import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";

// img
import IconNext from "resources/image/slide/icon_next.svg";
import IconPrev from "resources/image/slide/icon_prev.svg";
import ExImg from "resources/image/slide/ex-img-swiper.jpg";
import ImgBox from "../ImgBox";
import { NavLink } from "react-router-dom";
import { Mobile, PC } from "hooks/useMediaQuery";
import { IMG } from "constant";
import { IMAGEPATH } from "service/connector";

const BASE_VIEW = 3;
function SwiperWrap({ list, link, perView, perGroup }) {
  const [swiper, setSwiper] = useState(null);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };

  return (
    <Styles.SwiperWrapStyle>
      <div className="sw-container">
        <div className="swiper_wrap">
          <PC>
            <Swiper
              className="slider"
              slidesPerView={perView ? perView : BASE_VIEW}
              loop={true}
              speed={600}
              spaceBetween={30}
              slidesPerGroup={perGroup ? perGroup : BASE_VIEW}
              onSwiper={(e) => {
                setSwiper(e);
              }}
            >
              {list?.map((item, index) => {
                return (
                  <SwiperSlide key={"column-swiper" + index}>
                    <NavLink to={link + item.id}>
                      <div className="sw-column">
                        <div className="img-box">
                          <ImgBox type={item.keyword || item.type} src={(item.image && IMAGEPATH + item.image) || IMG.ExImg} />
                        </div>
                        <p className="title">{item.title}</p>
                        <span className="sub-title">{item.description_text}</span>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </PC>
          <Mobile>
            <Swiper
              className="slider"
              slidesPerView={BASE_VIEW / 2}
              loop={true}
              speed={600}
              spaceBetween={15}
              slidesPerGroup={perGroup ? perGroup : 1}
              centeredSlides={true}
              onSwiper={(e) => {
                setSwiper(e);
              }}
            >
              {list?.map((item, index) => {
                return (
                  <SwiperSlide key={"column-swiper" + index}>
                    <NavLink to={link + item.id}>
                      <div className="sw-column">
                        <div className="img-box">
                          <ImgBox type={item.keyword || item.type} src={(item.image && IMAGEPATH + item.image)} />
                        </div>
                        <p className="title">{item.title}</p>
                        <span className="sub-title">{item.description_text}</span>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Mobile>
        </div>
        <button className="slide_btn btn_prev" onClick={handlePrev}>
          <img src={IconPrev} alt="슬라이드 이전" />
        </button>
        <button className="slide_btn btn_next" onClick={handleNext}>
          <img src={IconNext} alt="슬라이드 다음" />
        </button>
      </div>
    </Styles.SwiperWrapStyle>
  );
}

export { SwiperWrap };

export default SwiperWrap;
