import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  height: 54px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  color: #111;
  border: 1px solid #C4C4C4;
  border-radius: 10px;

  &::placeholder {
    color: #C4C4C4;
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(54/1520));
    padding: 0 calc(100vw*(16/1520));
    font-size: calc(100vw*(16/1520));
    border-radius: calc(100vw*(10/1520));
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    height: calc(100vw*(54/375));
    padding: 0 calc(100vw*(16/375));
    font-size: calc(100vw*(16/375));
    border-radius: calc(100vw*(10/375));
  }
`;

export { Input };

export default { Input };
