import { useState, useEffect } from "react";
import Styles from './styles';
import { useDeviceType } from "hooks/useMediaQuery";

// img
import IconArrow from "resources/image/icon/icon_right_arrow_wh.svg";
import ProcessImg01 from "resources/image/main/process_img_01.jpg";
import ProcessImg02 from "resources/image/main/process_img_02.jpg";
import ProcessImg03 from "resources/image/main/process_img_03.jpg";
import ProcessImg04 from "resources/image/main/process_img_04.jpg";

function MainProcess() {
  const deviceType = useDeviceType();
  const [tabActive, setTabActive] = useState(0);
  const tabList = ["사전 조사 시뮬레이션", "자체 소변 • 모발 검사", "전문가 연계 양형자료", "조사 전 포렌식 자료 확보"]

  const calcTop = () => {
    if(deviceType === "pc") return tabActive*100;
    if(deviceType === "mobile"){
      return (tabActive*68) + (10*tabActive);
    }
  };

  return (
    <Styles.MainProcess>
      <div className='process_inner'>
        <div className="tab_menu">
          <h2>“왜”<br/>마약은 온강인가?</h2>
          <Styles.TabBtn className="tab_btn" top={calcTop}>
            {tabList?.map((item, index) => {
              return (
                <li
                  className={tabActive === index ? 'active' : ''}
                  onClick={()=>{setTabActive(index)}}
                  key={'tabList' + index}
                  >
                  <span>0{index + 1}</span>
                  <p>
                    {item}
                    {tabActive === index && <img src={IconArrow} alt={item} />}
                  </p>
                </li>
              )
            })}
          </Styles.TabBtn>
        </div>
        <div className="tab_contents">
          <div className={'tab_box' + (tabActive === 0 ? ' active' : '')}>
            <img src={ProcessImg01} alt="" />
            <p>마약 조사 연습실에서<br/>검사 출신 변호사와 경찰, 검찰조사<br/>시뮬레이션 진행</p>
          </div>
          <div className={'tab_box' + (tabActive === 1 ? ' active' : '')}>
            <img src={ProcessImg02} alt="" />
            <p>자체 간이시약 및 모발검사를 통해<br/>한 발 빠른 변론 전략 수립</p>
          </div>
          <div className={'tab_box' + (tabActive === 2 ? ' active' : '')}>
            <img src={ProcessImg03} alt="" />
            <p>정신건강의학과 중독 치료 전문의와<br/>연계하여 선처를 위한 양형 자료 철저히 준비</p>
          </div>
          <div className={'tab_box' + (tabActive === 3 ? ' active' : '')}>
            <img src={ProcessImg04} alt="" />
            <p>한국디지털포렌식센터와 협업을 통해<br/>전문적인 포렌식 수사 및 신속한 디지털<br/>증거 확보</p>
          </div>
        </div>
      </div>
    </Styles.MainProcess>
  );
}

export default MainProcess;