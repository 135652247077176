import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  & .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 475px; min-height: 256px;
    padding: 40px 20px 60px;
    background: #fff;
    & .closeButton {
      display: flex;
      align-items: center;
      position: absolute;
      top: -39px; right: 0;
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      & img {
        width: 24px; height: 24px;
        margin-left: 4px;
      }
    }
    & .title {
      padding-bottom: 14px;
      font-size: 20px;
      font-weight: 800;
      text-align: left;
      border-bottom: 2px solid #111;
    }
    & .inner {
      overflow-y: auto;
      margin-top: 30px;
      & .text {
        color: #111;
        line-height: 1.4;
        font-size: 16px;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(1, 1, 1, 0.7);
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .wrap {
      width: calc(100vw*(475/1520)); min-height: calc(100vw*(256/1520));
      padding: calc(100vw*(40/1520)) calc(100vw*(20/1520)) calc(100vw*(60/1520));
      & .closeButton {
        top: calc(100vw*(-39/1520));
        font-size: calc(100vw*(18/1520));
        & img {
          width: calc(100vw*(24/1520)); height: calc(100vw*(24/1520));
          margin-left: calc(100vw*(4/1520));
        }
      }
      & .title {
        padding-bottom: calc(100vw*(14/1520));
        font-size: calc(100vw*(20/1520));
      }
      & .inner {
        margin-top: calc(100vw*(30/1520));
        & .text {
          font-size: calc(100vw*(16/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .wrap {
      width: calc(100vw*(290/375)); min-height: calc(100vw*(225/375));
      padding: calc(100vw*(30/375)) calc(100vw*(15/375)) calc(100vw*(40/375));
      & .closeButton {
        top: calc(100vw*(-28/375));
        font-size: calc(100vw*(16/375));
        & img {
          width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
          margin-left: calc(100vw*(4/375));
        }
      }
      & .title {
        padding-bottom: calc(100vw*(14/375));
        font-size: calc(100vw*(18/375));
        letter-spacing: -0.8px;
        white-space: nowrap;
      }
      & .inner {
        margin-top: calc(100vw*(20/375));
        & .text {
          font-size: calc(100vw*(14/375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
