import Styles from "./styles";

function ImgBox({ type, src }) {
  return (
    <Styles.ImgBoxStyle className="img-box-container">
      <div className="img-box">
        {type && <p className="float-type">{type}</p>}
        <img src={src} />
      </div>
    </Styles.ImgBoxStyle>
  );
}

export { ImgBox };

export default ImgBox;
