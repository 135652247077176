import { useState, useEffect } from "react";
import Styles from "./styles.jsx";
import { Link } from "react-router-dom";

// img
import IconFooter from "resources/image/icon/icon_footer-logo.svg";
import IconKakao from "resources/image/icon/icon_kakaos.svg";
import IconYoutube from "resources/image/icon/icon_youtube_wh.svg";
import IconNaver from "resources/image/icon/icon_naver.svg";
import IconInsta from "resources/image/icon/icon_insta.svg";

// aside img
import IconArrowRight from "resources/image/icon/icon_quick_arrow_right.svg";
import IconArrowLeft from "resources/image/icon/icon_quick_arrow_left.svg";
import IconCall from "resources/image/icon/icon_call.svg";
import IconKakaoAside from "resources/image/icon/icon_kakao_aside.svg";
import IconTelegram from "resources/image/icon/icon_telegram.svg";
import IconArrowTopBlack from "resources/image/icon/icon_arrow-top-black.svg";

import IconDrawer from "resources/image/icon/icon_drawer.svg";
import IconCloseGray from "resources/image/icon/icon_close-gray.svg";

const CustomSelect = () => {
  const [isClick, setIsClick] = useState(false);

  const onClickSelect = () => {
    setIsClick((val) => !val);
  };

  return (
    <div className="select-box">
      <div className="select-box__inner">
        <p onClick={onClickSelect} className="title">
          Family site
          <span className="img-box">
            <img src={isClick ? IconCloseGray : IconDrawer} alt="drawer" />
          </span>
        </p>
        {isClick && (
          <ul className="select-list">
            <li className="select-item">
              <Link className="link" to="/">
                법무법인 온강 마약
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

const AsideBar = () => {
  const [active, setActive] = useState(true);

  const asideItem = [
    {
      text: "전화상담",
      src: IconCall,
      link: "tel:1670-5901",
    },
    {
      text: "카카오톡",
      src: IconKakaoAside,
      link: "https://pf.kakao.com/_UHiqxj",
    },
    {
      text: "텔레그램",
      src: IconTelegram,
      link: "https://t.me/ongang01",
    },
  ];

  return (
    <aside className={active ? "aside active" : "aside"}>
      <button onClick={()=>{setActive(!active)}} className={active ? 'active' : ''}>
        <img src={active ? IconArrowLeft : IconArrowRight } alt="" />
      </button>
      <ul className="aside-list">
        {asideItem.map((item, idx) => {
          return (
            <li className="item" key={idx}>
              <a href={item.link} target="_blank" className="item-box link" rel="noreferrer">
                <img src={item.src} alt="" />
                <p>{item.text}</p>
              </a>
            </li>
          );
        })}
        <li className="item toTop">
          <div
            className="item-box link"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <img src={IconArrowTopBlack} alt="" />
            <p>TOP</p>
          </div>
        </li>
      </ul>
    </aside>
  );
};

const FooterSnsList = [
  { id: 1, imgSrc: IconKakao, linkTo: "https://pf.kakao.com/_UHiqxj" },
  { id: 2, imgSrc: IconYoutube, linkTo: "https://www.youtube.com/playlist?list=PLboXQKpisWdrFi6QnhR7Je-iNTdblDzPr" },
  { id: 3, imgSrc: IconNaver, linkTo: "https://blog.naver.com/ongangdrug" },
  { id: 4, imgSrc: IconInsta, linkTo: "https://www.instagram.com/mylifelawyer" },
];

const onCall = () => {
  window.location.href = "tel:1670-5901";
};

export default function Footer() {
  return (
    <Styles.Footer>
      <div className="footer-container">
        <div className="footer-inner">
          <ul className="inner-list">
            <li className="inner-list--item left">
              <div className="img-box footer-logo">
                <img src={IconFooter} alt="온강 푸터 로고" />
              </div>
              <ul className="text-list">
                <li className="text-list--item heading">법무법인 온강</li>
                <li className="text-list--item">
                  <span className="text">사업자등록번호 : 593-81-03100</span>
                  <span className="text">대표 : 배한진</span>
                  <span className="text">광고책임변호사 : 배한진</span>
                  <span className="text">주소 : 서울 서초구 서초대로 254, 오퓨런스빌딩 3등 302호</span>
                  <span className="text">Fax : 02-6915-8059</span>
                  <span className="text">이메일 : ongang@ongang.kr</span>
                  <span className="text">Copyright. 법무법인 온강. all rights reserved.</span>
                </li>
              </ul>
            </li>
            <li className="inner-list--item right">
              <ul className="info-list">
                <li className="info-list__item--heading">
                  <p className="heading__text">온강 24시 마약센터</p>
                  <p className="heading__phone" onClick={onCall}>
                    1670-5901
                  </p>
                  <p className="heading__subtext">24h 긴급대응, 상담예약</p>
                </li>
                <li className="info-list__item--sns">
                  <ul className="sns-list">
                    {FooterSnsList.map((footerItem, footerIdx) => {
                      return (
                        <li className="sns-list--item" key={footerIdx}>
                          <a href={footerItem.linkTo} target="_blank" rel="noreferrer">
                            <img src={footerItem.imgSrc} alt="" />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li className="info-list__item--family-site">
                  <CustomSelect />
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <AsideBar />
        
      </div>
    </Styles.Footer>
  );
}
