import styled from 'styled-components';

// img
import SystemBg from "resources/image/system/system_bg.jpg";
import MbSystemBg from "resources/image/system/mb_system_bg.jpg";
import Logo from "resources/image/icon/system_logo_wh.svg";

const SystemPage = styled.div`
  position: relative;
  width: 100%; height: 1560px;
  padding-top: 100px;
  padding-bottom: 150px;
  background: #000 url(${SystemBg}) no-repeat center bottom / 1920px;
  & .system_inner {
    width: 1260px;
    margin: 0 auto;
    & .tit {
      font-size: 42px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      & span {
        color: #DA250B;
        font-weight: 800;
      }
    }
    & .chart_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 156px;
      &::after {
        content: '';
        position: absolute;
        left: 50%; top: 50%;
        display: block;
        width: 76px; height: 92px;
        background: url(${Logo}) no-repeat center/contain;
        transform: translate(-50%, -50%);
      }
      & .chart_img {
        position: relative;
        width: 492px; height: 492px;
      }
      & .index_list {
        position: absolute;
        width: 100%; height: 100%;
        & li {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #888;
          & i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px; height: 80px;
            background-color: #111;
            border-radius: 10px;
            font-size: 36px;
            font-weight: 700;
            box-shadow: 0 0 18px rgba(1,1,1,0.8);
            transition: 0.5s;
            cursor: pointer;
          }
          & span {
            display: block;
            width: 245px;
            font-size: 26px;
            font-weight: 600;
            letter-spacing: -0.5px;
            line-height: 36px;
            transition: 0.5s;
          }
          &.active {
            & i {
              background-color: #DA250B;
              color: #fff;
            }
            & span {
              color: #fff;
              font-weight: 800;
            }
          }
          &:first-child {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            top: -115px;
            left: 50%;
            transform: translateX(-50%);
            & span {
              text-align: center;
              margin-bottom: 22px;
            }
          }
          &:nth-child(2) {
            align-items: center;
            justify-content: center;
            top: 78px;
            right: 85px;
            & span {
              margin-left: 20px;
            }
          }
          &:nth-child(3) {
            align-items: center;
            justify-content: center;
            bottom: 80px;
            right: 85px;
            & span {
              margin-left: 20px;
            }
          }
          &:nth-child(4) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            bottom: -115px;
            left: 50%;
            transform: translateX(-50%);
            & span {
              text-align: center;
              margin-top: 22px;
            }
          }
          &:nth-child(5) {
            flex-direction: row-reverse;
            bottom: 80px;
            left: 85px;
            & span {
              margin-right: 20px;
              text-align: right;
            }
          }
          &:last-child {
            flex-direction: row-reverse;
            top: 78px;
            left: 85px;
            & span {
              margin-right: 20px;
              text-align: right;
            }
          }
        }
      }
    }
    & .system_slider {
      width: 100%;
      margin-top: 156px;
      border: 2px solid #DA250B;
      & .swiper-slide {
        & .system_slide {
          display: flex;
          align-items: center;
          & .slide_img {
            width: 548px; height: 380px;
          }
          & .system_text {
            padding-left: 60px;
            color: #fff;
            & .num {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px; height: 48px;
              background-color: #DA250B;
              border-radius: 5px;
              font-size: 30px;
              color: #fff;
              font-weight: 700;
            }
            & .tit {
              font-size: 26px;
              font-weight: bold;
              text-align: left;
              line-height: 31px;
              margin-top: 20px;
              & span {color: #DA250B;}
            }
            & .dec {
              font-size: 18px;
              line-height: 29px;
              font-weight: 500;
              color: #C4C4C4;
              margin-top: 20px;
              letter-spacing: -0.4px;
              word-wrap: break-word;
              word-break: keep-all;
              & b {
                font-weight: 800;
                color: #fff;
              }
            }
            & .dec_list {
              width: 535px;
              margin-top: 20px;
              & li {
                position: relative;
                margin-top: 0;
                padding-left: 26px;
                &::before {
                  content: '';
                  position: absolute;
                  top: 13px; left: 8px;
                  display: block;
                  width: 5px; height: 5px;
                  border-radius: 50%;
                  background-color: #fff;
                }
              }
              & p {
                line-height: 27px;
              }
            }
          }
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(1560/1520));
    padding-top: calc(100vw*(100/1520));
    padding-bottom: calc(100vw*(150/1520));
    background-size: calc(100vw*(1920/1520));
    & .system_inner {
      width: calc(100vw*(1260/1520));
      & .tit {
        font-size: calc(100vw*(42/1520));
        line-height: calc(100vw*(60/1520));
      }
      & .chart_wrap {
        margin-top: calc(100vw*(156/1520));
        &::after {
          width: calc(100vw*(76/1520)); height: calc(100vw*(92/1520));
        }
        & .chart_img {
          width: calc(100vw*(492/1520)); height: calc(100vw*(492/1520));
        }
        & .index_list {
          & li {
            & i {
              width: calc(100vw*(80/1520)); height: calc(100vw*(80/1520));
              border-radius: calc(100vw*(10/1520));
              font-size: calc(100vw*(36/1520));
              box-shadow: 0 0 calc(100vw*(18/1520)) rgba(1,1,1,0.8);
            }
            & span {
              width: calc(100vw*(245/1520));
              font-size: calc(100vw*(26/1520));
              line-height: calc(100vw*(36/1520));
            }
            &:first-child {
              top: calc(100vw*(-115/1520));
              & span {
                margin-bottom: calc(100vw*(22/1520));
              }
            }
            &:nth-child(2) {
              top: calc(100vw*(78/1520));
              right: calc(100vw*(85/1520));
              & span {
                margin-left: calc(100vw*(20/1520));
              }
            }
            &:nth-child(3) {
              bottom: calc(100vw*(80/1520));
              right: calc(100vw*(85/1520));
              & span {
                margin-left: calc(100vw*(20/1520));
              }
            }
            &:nth-child(4) {
              bottom: calc(100vw*(-115/1520));
              & span {
                margin-top: calc(100vw*(22/1520));
              }
            }
            &:nth-child(5) {
              bottom: calc(100vw*(80/1520));
              left: calc(100vw*(85/1520));
              & span {
                margin-right: calc(100vw*(20/1520));
              }
            }
            &:last-child {
              top: calc(100vw*(78/1520));
              left: calc(100vw*(85/1520));
              & span {
                margin-right: calc(100vw*(20/1520));
              }
            }
          }
        }
      }
      & .system_slider {
        margin-top: calc(100vw*(156/1520));
        & .swiper-slide {
          & .system_slide {
            & .slide_img {
              width: calc(100vw*(548/1520)); height: calc(100vw*(380/1520));
            }
            & .system_text {
              padding-left: calc(100vw*(60/1520));
              & .num {
                width: calc(100vw*(48/1520)); height: calc(100vw*(48/1520));
                border-radius: calc(100vw*(5/1520));
                font-size: calc(100vw*(30/1520));
              }
              & .tit {
                font-size: calc(100vw*(26/1520));
                line-height: calc(100vw*(31/1520));
                margin-top: calc(100vw*(20/1520));
              }
              & .dec {
                font-size: calc(100vw*(18/1520));
                line-height: calc(100vw*(29/1520));
                margin-top: calc(100vw*(20/1520));
              }
              & .dec_list {
                width: calc(100vw*(535/1520));
                margin-top: calc(100vw*(20/1520));
                & li {
                  padding-left: calc(100vw*(26/1520));
                  &::before {
                    top: calc(100vw*(13/1520)); left: calc(100vw*(8/1520));
                    width: calc(100vw*(5/1520)); height: calc(100vw*(5/1520));
                  }
                }
                & p {
                  line-height: calc(100vw*(27/1520));
                }
              }
            }
          }
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    height: auto;
    padding-top: calc(100vw*(50/375));
    padding-bottom: calc(100vw*(295/375));
    background-image: url(${MbSystemBg});
    background-position: bottom center;
    background-size: 100%;
    & .system_inner {
      width: 100%;
      & .tit {
        font-size: calc(100vw*(26/375));
        line-height: calc(100vw*(39/375));
      }
      & .chart_wrap {
        margin-top: calc(100vw*(40/375));
        &::after {
          display: none;
        }
        & .chart_img {
          display: none;
        }
        & .index_list {
          position: initial;
          display: flex;
          flex-direction: column;
          gap: calc(100vw*(20/375));
          width: 100%;
          padding: 0 calc(100vw*(20/375));
          & li {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: initial;
            & i {
              width: calc(100vw*(50/375)); height: calc(100vw*(50/375));
              border-radius: calc(100vw*(10/375));
              font-size: calc(100vw*(26/375));
              box-shadow: 0 0 calc(100vw*(18/375)) rgba(1,1,1,0.8);
            }
            & span {
              width: calc(100% - calc(100vw*(50/375)));
              font-size: calc(100vw*(18/375));
              line-height: calc(100vw*(20/375));
              text-align: left;
              padding-left: calc(100vw*(20/375));
            }
            &:first-child {
              flex-direction: row;
              align-items: center;
              transform: none;
              & span {
                margin-bottom: 0;
                text-align: left;
              }
            }
            &:nth-child(2) {
              flex-direction: row;
              align-items: center;
              transform: none;
              & span {
                margin-right: 0;
                text-align: left;
              }
            }
            &:nth-child(3) {
              flex-direction: row;
              align-items: center;
              transform: none;
              & span {
                margin-right: 0;
                text-align: left;
              }
            }
            &:nth-child(4) {
              flex-direction: row;
              align-items: center;
              transform: none;
              & span {
                margin-top: 0;
                text-align: left;
              }
            }
            &:nth-child(5) {
              flex-direction: row;
              align-items: center;
              transform: none;
              & span {
                margin-right: 0;
                text-align: left;
              }
            }
            &:last-child {
              flex-direction: row;
              align-items: center;
              transform: none;
              & span {
                margin-right: 0;
                text-align: left;
              }
            }
          }
        }
      }
      & .system_slider {
        margin-top: calc(100vw*(50/375));
        width: calc(100vw*(335/375));
        & .swiper-slide {
          & .system_slide {
            flex-direction: column;
            height: auto;
            & .slide_img {
              width: 100%; height: calc(100vw*(230/375));
            }
            & .system_text {
              width: 100%;
              padding: calc(100vw*(30/375)) calc(100vw*(20/375));
              & .num {
                width: calc(100vw*(34/375)); height: calc(100vw*(34/375));
                border-radius: calc(100vw*(5/375));
                font-size: calc(100vw*(18/375));
              }
              & .tit {
                font-size: calc(100vw*(18/375));
                line-height: calc(100vw*(21/375));
                margin-top: calc(100vw*(10/375));
              }
              & .dec {
                font-size: calc(100vw*(14/375));
                line-height: calc(100vw*(22/375));
                margin-top: calc(100vw*(10/375));
                letter-spacing: -0.3px;
                & br {
                  display: none;
                }
              }
              & .dec_list {
                width: 100%;
                margin-top: calc(100vw*(20/375));
                padding-right: calc(100vw*(20 / 375));
                & li {
                  padding-left: calc(100vw*(26/375));
                  &::before {
                    top: calc(100vw*(13/375)); left: calc(100vw*(8/375));
                    width: calc(100vw*(5/375)); height: calc(100vw*(5/375));
                  }
                }
                & p {
                  line-height: calc(100vw*(27/375));
                }
              }
            }
          }
        }
      }
    }
  }
`;




export {
  SystemPage
}

export default {
  SystemPage
}