import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FloatHeading from "components/layout/FloatHeading";
import Intro from "./components/Intro";
import IntroSpecial from "./components/IntroSpecial";
import IntroExpert from "./components/IntroExpert";
import IntroNews from "./components/IntroNews";
import IntroMap from "./components/IntroMap";

import { INTRO_HEADING_DATA } from "constant";

// TODO:: 배너 및 투명도 없는 이미지 JPG로
// TODO:: 2-3 detail 더보기 스타일링

const IntroPage = () => {
  const { contents } = useParams();
  const renderComponents = {
    introduce: <Intro />, // 2-1 온강 소개
    special: <IntroSpecial />, // 2-2 온강의 특별함
    expert: <IntroExpert />, // 2-3 전문가 소개
    news: <IntroNews />, // 2-4 언론보도
    map: <IntroMap />, // 2-5 오시는 길
  };

  return (
    <div className="intro page">
      <div className="inner">
        <FloatHeading data={INTRO_HEADING_DATA} />
        {renderComponents[contents] || <div>Error!</div>}
      </div>
    </div>
  );
};

export default IntroPage;
