import { ICON } from "constant";
import React from "react";
import styled from "styled-components";

const MoreBtn = ({ onClickBtn, title, style, icon }) => {
  return (
    <MoreBtnStyle className="more-btn" style={style} onClick={onClickBtn}>
      <span>{title}</span>
      <img src={icon ? icon : ICON.ICON_PLUS_BK} alt="" />
    </MoreBtnStyle>
  );
};

export default MoreBtn;

const MoreBtnStyle = styled.button`
  & {
    width: auto;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #111;
    border-radius: 50px;

    & span {
      font-size: inherit;
    }
    & img {
      width: 18px;
      margin-left: 30px;
    }
  }

  @media (max-width: 1520px) {
    & {
      padding: calc(100vw * (12 / 1520)) calc(100vw * (30 / 1520));
      font-size: calc(100vw * (18 / 1520));

      & img {
        width: calc(100vw * (18 / 1520));
        margin-left: calc(100vw * (30 / 1520));
      }
    }
  }

  @media (max-width: 1024px) {
    & {
      padding: calc(100vw * (10 / 375)) calc(100vw * (20 / 375));
      font-size: calc(100vw * (16 / 375));

      & img {
        width: calc(100vw * (18 / 375));
        margin-left: calc(100vw * (20 / 375));
      }
    }
  }

  ${(props) => props.style && props.style};
`;
