import styled from "styled-components";

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; height: 660px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  & .banner_inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 1260px;
    margin: 0 auto;
    & .line_img {
      position: absolute;
      left: 0; top: 18px;
      width: 58px;
    }
    & .bnnr_text {
      padding-left: 78px;
    }
    & h2 {
      font-size: 62px;
      line-height: 80px;
      font-weight: 600;
      color: #fff;
    }
    & p {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #fff;
      margin-top: 30px;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(660/1520));
    & .banner_inner {
      width: calc(100vw*(1260/1520));
      & .line_img {
        top: calc(100vw*(18/1520));
        width: calc(100vw*(58/1520));
      }
      & .bnnr_text {
        padding-left: calc(100vw*(78/1520));
      }
      & h2 {
        font-size: calc(100vw*(62/1520));
        line-height: calc(100vw*(80/1520));
      }
      & p {
        font-size: calc(100vw*(16/1520));
        line-height: calc(100vw*(24/1520));
        margin-top: calc(100vw*(30/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    height: calc(100vw*(360/375));
    & .banner_inner {
      width: calc(100vw*(335/375));
      & .line_img {
        top: calc(100vw*(6/375));
        width: calc(100vw*(30/375));
      }
      & .bnnr_text {
        padding-left: calc(100vw*(40/375));
      }
      & h2 {
        font-size: calc(100vw*(30/375));
        line-height: calc(100vw*(34/375));
      }
      & p {
        font-size: calc(100vw*(14/375));
        line-height: calc(100vw*(21/375));
        margin-top: calc(100vw*(10/375));
      }
    }
  }
`;

export { Banner };

export default { Banner };
