import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Styles from './styles';

// img
import IconLogoWh from "resources/image/icon/icon_logo_wh.svg";
import IconLogo from "resources/image/icon/icon_logo.svg";
import IconGnb from "resources/image/icon/icon_gnb.svg";
import IconGnbBk from "resources/image/icon/icon_gnb_bk.svg";
import IconClosed from "resources/image/icon/icon_closed.svg";
import IconCounsel from "resources/image/icon/icon_counsel.svg";

function MbHeader() {
  const ref = useRef();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [gnb, setGnb] = useState(false);
  const [gnbActive, setGnbActive] = useState(null);
  const gnbList = [
    {
      title: "온강 마약센터",
      open: false,
      list: [
        { title: "온강 소개", url: "/intro/introduce" },
        { title: "온강의 특별함", url: "/intro/special" },
        { title: "전문가 소개", url: "/intro/expert" },
        { title: "언론보도", url: "/intro/news" },
        { title: "오시는 길", url: "/intro/map" },
      ],
    },
    {
      title: "성공사례",
      open: false,
      list: [
        { title: "성공사례", url: "/example" },
      ],
    },
    {
      title: "상황별 대응전략",
      open: false,
      list: [
        { title: "구매 투약", url: "/situation/medication" },
        { title: "판매유통", url: "/situation/sales" },
        { title: "밀수제조", url: "/situation/smuggling" },
        { title: "체포구속상태", url: "/situation/arrest" },
      ],
    },
    {
      title: "프리미엄 시스템",
      open: false,
      list: [
        { title: "온강의<br>프리미엄 시스템", url: "/system" },
      ],
    },
    {
      title: "온강 이야기",
      open: false,
      list: [
        { title: "의뢰인 후기", url: "/story/review" },
        { title: "유튜브", url: "/story/youtube" },
        { title: "컬럼", url: "/story/column" },
      ],
    },
  ]

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY > 0)
  };

  const scrollHidden = (state) => {
    const body = document.body
    const html = document.querySelector('html')
    if(state){
      body.className = "hidden";
      html.className = "hidden";
    }else{
      body?.classList?.remove('hidden');
      html?.classList?.remove('hidden');
    }
  };

  const lnbLink = (url) => {
    setGnb(!gnb);
    scrollHidden(!gnb)
    navigate(url);
  };

  return (
    <>
      <Styles.Header id="header" ref={ref} className={scroll || gnb? "on" : ""}>
        <header className="header">
          <h1
            className="logo"
            onClick={() => {navigate("/");}}
          >
            <img src={scroll || gnb ? IconLogo : IconLogoWh} alt="logo" />
          </h1>
          <button onClick={()=>{setGnb(!gnb); scrollHidden(!gnb)}} className="btn_gnb">
            { gnb ?
              <img src={IconClosed} alt="gnb" />
              :
              <img src={scroll ? IconGnbBk : IconGnb} alt="gnb" />
            }
          </button>
        </header>
      </Styles.Header>
      { gnb &&
        <Styles.Gnb>
          <div className="gnb_inner">
            <button className="btn_counsel" onClick={() => {lnbLink("/counsel");}}>
              <img src={IconCounsel} alt="logo" />
              <span>상담신청</span>
            </button>
            <ul className="gnb">
              {gnbList.map((value,index)=>{
                return (
                  <li key={"mb_gnbList" + index}>
                    <span
                      onClick={()=>{setGnbActive(gnbActive === index ? null : index)}}
                      className={gnbActive === index ? 'active' : ''}
                    >- {value.title}</span>
                    {
                      gnbActive === index &&
                      <ul className="lnb">
                        {
                          value.list.map((v,i) => {
                            return (
                              <li key={"mb_gnbSubList" + i} onClick={() => {lnbLink(v.url)}}>
                                <span dangerouslySetInnerHTML={{ __html: v.title }}/>
                              </li>
                            )
                          })
                        }
                      </ul>
                    }
                  </li>
                )
              })}
            </ul>
            <div className="gnb_bottom">
              <p className="tit">법무법인 온강</p>
              <p className="dec">사업자등록번호 : 593-81-03100</p>
              <p className="dec">대표 : 배한진</p>
              <p className="dec">광고책임변호사 : 배한진</p>
              <p className="dec">주소 : 서울 서초구 서초대로 254, 오퓨런스빌딩 3등 302호</p>
              <p className="dec">Fax : 02-6915-8059</p>
              <p className="dec">이메일 : ongang@ongang.kr</p>
              <p className="dec">Copyright. 법무법인 온강. all rights reserved.</p>
            </div>
          </div>
        </Styles.Gnb>
      }
    </>
  );
}

export { MbHeader };

export default MbHeader;