import React, { useState } from "react";

const IntroMap = () => {
  const [isActive, setIsActive] = useState(0);
  const TAB_LIST = [
    {
      id: 1,
      title: "주사무소",
      address: (
        <>
          서울 서초구 서초대로 254, 오퓨런스빌딩 3층 302호
          <br />
          (서초역 1번 출구 3분 거리 투썸플레이스 건물 3층)
        </>
      ),
      call: "1670-5901",
      time: "오전 8시~오후 10시 (24시간 연중무휴 긴급대응 가능)",
      mail: "ongang@ongang.kr",
    },
    {
      id: 2,
      title: "인천분사무소",
      address: (
        <>
          인천광역시 미추홀구 소성로185번길 23,
          <br />
          육삼빌딩 5층
        </>
      ),
      call: "1670-5901",
      time: "오전 8시~오후 10시 (24시간 연중무휴 긴급대응 가능)",
      mail: "ongang@ongang.kr",
    },
    {
      id: 3,
      title: "안산분사무소",
      address: <>경기도 안산시 단원구 광덕서로 62, 601호</>,
      call: "1670-5901",
      time: "오전 8시~오후 10시 (24시간 연중무휴 긴급대응 가능)",
      mail: "ongang@ongang.kr",
    },
  ];
  return (
    <section className="section expert map full">
      <div className="expert-map">
        <div className="map-inner">
          <div className="map-contents">
            <ul className="map-list">
              {TAB_LIST.map((mapItem, mapIdx) => {
                return (
                  <li
                    className="map-item"
                    onClick={() => {
                      setIsActive(mapIdx);
                    }}
                    key={mapIdx}
                  >
                    <div className={`${isActive === mapIdx ? "active" : ""} map-item-inner`}>{mapItem.title}</div>
                  </li>
                );
              })}
            </ul>
            MAP
          </div>
          <div className="map-info">
            <ul className="map-info-list">
              <li className="map-info-item">
                <div className="map-text">
                  <p className="title">주소</p>
                  <p className="sub-title">{TAB_LIST[isActive].address}</p>
                </div>
                <div className="map-text">
                  <p className="title">대표전화</p>
                  <p className="sub-title">{TAB_LIST[isActive].call}</p>
                </div>
              </li>

              <li className="map-info-item">
                <div className="map-text">
                  <p className="title">업무시간</p>
                  <p className="sub-title">{TAB_LIST[isActive].time}</p>
                </div>
                <div className="map-text">
                  <p className="title">메일</p>
                  <p className="sub-title">{TAB_LIST[isActive].mail}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroMap;
