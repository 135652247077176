import styled, { css } from 'styled-components';


const MainLawyerList = styled.div`
  position: relative;
  padding: 100px 0;

  & .lawyer_inner {
    width: 1500px;
    margin: 0 auto;
    & h2 {
      font-size: 56px;
      line-height: 72px;
      font-weight: 400;
      text-align: center;
      letter-spacing: -0.5px;
      & strong {
        font-weight: 800;
      }
    }
    & .fixed_list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding-top: 50px;
      & .fixed_box {
        width: 200px;
        cursor: pointer;
        & .fixed_img {
          position: relative;
          background: linear-gradient(180deg, #222 0%, #888 100%);
          transition: 0.5s;
          overflow: hidden;
          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0; top: 50%;
            width: 100%; height: 100%;
            background: linear-gradient(180deg, #222 -7.21%, #B21600 119.12%);
            opacity: 0;
            transition: 0.5s;
          }
          & img {
            position: relative;
            display: block;
            width: 100%; height: 340px;
            object-fit: cover;
          }
        }
        & .name {
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
        }
        & .dec {
          display: block;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          margin-top: 10px;
          color: #888;
        }
        & .btn_more {
          display: none;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          font-weight: bold;
          text-align: center;
          margin-top: 10px;
          color: #DA250B;
        }
        &:hover {
          & .fixed_img::before {
            top: 0;
            opacity: 1;
          }
          & .dec {
            display: none;
          }
          & .btn_more {
            display: block;
          }
        }
      }
    }
    & .swiper_wrap {
      position: relative;
      width: 100%;
      padding: 0 120px;
      margin-top: 50px;
      & .lawyer_slide {
        position: relative;
        width: 100%; height: 100%;
        cursor: pointer;
        & .lawyer_img {
          display: block;
          width: 100%; height: 100%;
          opacity: 0.3;
          filter: grayscale(100%);
          transition: 0.5s;
        }
        &:hover {
          & .lawyer_img {
            filter: none;
            opacity: 1;
          }
        }
      }
      & .slide_btn {
        position: absolute;
        top: 50%;
        width: 70px; height: 70px;
        transform: translateY(-50%);
        transition: 0.3s;
        &.btn_prev {
          left: 0;
        }
        &.btn_next {
          right: 0;
        }
        &.disabled {
          opacity: 0.2;
        }
      }
    }
    & .swiper {
      width: 100%;
      & .swiper-slide {
        width: 128px; height: 174px;
      }
    }
  }
  
  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw*(100/1520)) 0;
    & .lawyer_inner {
      width: calc(100vw*(1500/1520));
      & h2 {
        font-size: calc(100vw*(56/1520));
        line-height: calc(100vw*(72/1520));
      }
      & .fixed_list {
        gap: calc(100vw*(12/1520));
        padding-top: calc(100vw*(50/1520));
        & .fixed_box {
          width: calc(100vw*(200/1520));
          & .fixed_img {
            & img {
              width: 100%; height: calc(100vw*(340/1520));
            }
          }
          & .name {
            font-size: calc(100vw*(20/1520));
            line-height: calc(100vw*(24/1520));
            margin-top: calc(100vw*(20/1520));
          }
          & .dec {
            font-size: calc(100vw*(16/1520));
            line-height: calc(100vw*(19/1520));
            margin-top: calc(100vw*(10/1520));
          }
          & .btn_more {
            font-size: calc(100vw*(16/1520));
            line-height: calc(100vw*(19/1520));
            margin-top: calc(100vw*(10/1520));
          }
        }
      }
      & .swiper_wrap {
        padding: 0 calc(100vw*(120/1520));
        margin-top: calc(100vw*(50/1520));
        & .slide_btn {
          width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
        }
      }
      & .swiper {
        & .swiper-slide {
          width: calc(100vw*(128/1520)); height: calc(100vw*(174/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw*(80/375)) 0;
    & .lawyer_inner {
      width: 100%;
      & h2 {
        font-size: calc(100vw*(36/375));
        line-height: calc(100vw*(43/375));
      }
      & .fixed_list {
        flex-wrap: wrap;
        gap: calc(100vw*(30/375)) calc(100vw*(10/375));
        padding-top: calc(100vw*(30/375));
        & .fixed_box {
          width: calc(100vw*(105/375));
          & .fixed_img {
            & img {
              width: 100%; height: calc(100vw*(179/375));
            }
          }
          & .name {
            font-size: calc(100vw*(16/375));
            line-height: calc(100vw*(19/375));
            margin-top: calc(100vw*(15/375));
          }
          & .dec {
            font-size: calc(100vw*(14/375));
            line-height: calc(100vw*(17/375));
            margin-top: calc(100vw*(5/375));
          }
          & .btn_more {
            font-size: calc(100vw*(16/375));
            line-height: calc(100vw*(19/375));
            margin-top: calc(100vw*(10/375));
          }
        }
      }
      & .swiper_wrap {
        padding: 0;
        margin-top: calc(100vw*(50/375));
        & .slide_btn {
          width: calc(100vw*(30/375)); height: calc(100vw*(30/375));
          z-index: 2;
        }
      }
      & .swiper {
        & .swiper-slide {
          width: calc(100vw*(128/375)); height: calc(100vw*(174/375));
        }
      }
    }
  }
`;




export {
  MainLawyerList
}

export default {
  MainLawyerList
}